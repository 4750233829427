var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"centertitle",style:({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    lineHeight: '1.5',
    color: '#ffffff',
    textAlign: 'center',
    textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
  }),attrs:{"id":"itemd86d3f90-3deb-11ec-a2e6-953464dcce14"}},[_c('p',[_vm._v("按组织形式统计")])]),_vm._m(0),_c('div',{attrs:{"id":"item85e23400-9797-4236-9be8-186a0b1f24e2"}},[_c('img',{style:({ width: '100%', height: '100%' }),attrs:{"src":require("../../../assets/images/线框.png"),"alt":""}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"leftBottomShowWindow2"}},[_c('div',{staticClass:"innerBox"},[_c('div',{attrs:{"id":"leftBottomEchartsBox21"}}),_c('div',{attrs:{"id":"leftBottomEchartsBox22"}}),_c('div',{attrs:{"id":"leftBottomEchartsBox23"}})])])
}]

export { render, staticRenderFns }