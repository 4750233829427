<template>
  <!-- 右边开始 -->
  <div id="item7a111060-3e05-11ec-a2e6-953464dcce14">
    <!-- 标题部分容器 -->
    <div id="titleBox">
      <h3>组织形式数量占比</h3>
      <div class="boderBottom"></div>
    </div>
    <!-- 环图的容器 -->
    <div id="topRightRingEchart4"></div>
  </div>
  <!-- 右边结束 -->
</template>

<script>
import * as echarts from 'echarts'
export default {
  mounted () {
    this.setEcharts()
  },
  data () {
    return {
      echartData: [
        { value: 25, name: '入围合同' },
        { value: 20, name: '单价合同' },
        { value: 318, name: '总价合同' }
      ]
    }
  },
  methods: {
    setEcharts () {
      var chartDom = document.getElementById('topRightRingEchart4')
      var myChart = echarts.init(chartDom)
      var xData = []
      var yData = []
      var max = 0
      var showXTxt = ''
      var echartSeries = []
      this.echartData.forEach((item, index) => {
        xData.push(item.name)
        yData.push(item.value)
        max += item.value
        if (index !== this.echartData.length - 1) {
          showXTxt += `${item.name}: ${item.value} \n`
        } else {
          showXTxt += `${item.name}: ${item.value}`
        }
        echartSeries.push({
          type: 'bar',
          name: item.name,
          barWidth: 20,
          data: [item.value],
          coordinateSystem: 'polar',
          showBackground: true
        })
      })
      var option
      option = {
        legend: {
          show: true,
          data: xData,
          top: 10,
          left: 'center',
          icon: 'rect',
          itemWidth: 14,
          itemGap: 40,
          padding: 0,
          textStyle: {
            color: '#ccc',
            fontSize: 18
          }
        },
        polar: {
          radius: [0, '100%']
        },
        angleAxis: {
          max: max,
          startAngle: 90,
          splitLine: false,
          axisTick: false,
          // 轴线文字是否展示
          axisLabel: {
            show: false
          },
          // 轴线是否显示
          axisLine: {
            show: false
          },
          inverse: true
        },
        radiusAxis: {
          type: 'category',
          splitLine: false,
          axisTick: false,
          data: [showXTxt],
          // 轴线是否显示
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: -110,
            show: true,
            fontSize: 16,
            lineHeight: 26,
            color: '#fff'
          },
          z: 2
        },
        tooltip: {},
        // [20,25,318]
        series: echartSeries
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
#item7a111060-3e05-11ec-a2e6-953464dcce14 {
  height: 600px;
  width: 460px;
}
#titleBox {
  margin-top: 30px;
  padding-left: 110px;
  color: #fff;
}
#titleBox h3 {
  position: relative;
  padding-left: 30px;
  font-size: 20px;
}
#titleBox h3::before {
  content: '';
  display: block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../../../assets/images/pie.png) no-repeat center 2px;
}
#titleBox .boderBottom {
  width: 100%;
  height: 4px;
  background: rgba(69, 148, 173, 1);
  background: linear-gradient(to right, rgba(69, 148, 173, 1), rgba(69, 148, 173, 0));
  margin-top: 10px;
}
#topRightRingEchart4 {
  width: 510px;
  height: 480px;
  margin: 20px auto;
}
</style>
