<template>
  <!-- 左边开始 -->
  <div id="item8389fda0-3e05-11ec-a2e6-953464dcce14">
    <!-- 选项卡容器 -->
    <!-- <div id="chooseCard"></div> -->
    <!-- 标题部分容器 -->
    <div id="titleBox">
      <h3>合同类型数量占比</h3>
      <div class="boderBottom"></div>
    </div>
    <!-- 环图的容器 -->
    <div id="topLeftRingEchart"></div>
  </div>
  <!-- 左边结束 -->
</template>

<script>
import * as echarts from 'echarts'
export default {
  mounted () {
    this.setEcharts()
  },
  data () {
    return {
      echartData: [
        { value: 363, name: '总价合同' },
        { value: 10, name: '入团合同' },
        { value: 77, name: '单价合同' }
      ]
    }
  },
  methods: {
    setEcharts () {
      var chartDom = document.getElementById('topLeftRingEchart')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        // tooltip: {
        //   trigger: "item",
        // },
        legend: {
          show: true,
          top: 10,
          left: 'center',
          icon: 'rect',
          itemWidth: 14,
          itemGap: 40,
          padding: 0,
          textStyle: {
            color: '#fff',
            fontSize: 18
          }
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['45%', '70%'],
            top: 0,
            left: 50,
            itemStyle: {
              // borderColor: '#fff',
              // borderWidth: 1
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "40",
            //     fontWeight: "bold",
            //   },
            // },
            label: {
              alignTo: 'edge',
              formatter: '{b} \n {c} %',
              minMargin: 5,
              edgeDistance: 20,
              lineHeight: 20,
              fontSize: 16,
              color: '#fff',
              rich: {
                // vaule: {
                // fontSize: 20,
                // color: "#fff",
                // },
              }
            },
            labelLine: {
              length: 30,
              length2: -30,
              maxSurfaceAngle: 80
            },
            labelLayout: function (params) {
              const isLeft = params.labelRect.x < myChart.getWidth() / 2
              const points = params.labelLinePoints
              // Update the end point.
              points[2][0] = isLeft
                ? params.labelRect.x
                : params.labelRect.x + params.labelRect.width
              return {
                labelLinePoints: points
              }
            },
            data: this.echartData
          }
        ]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
#item8389fda0-3e05-11ec-a2e6-953464dcce14 {
  height: 600px;
  width: 570px;
  /* margin-left: 110px; */
}
#titleBox {
  margin-top: 30px;
  padding-left: 110px;
  color: #fff;
}
#titleBox h3 {
  position: relative;
  padding-left: 30px;
  font-size: 20px;
}
#titleBox h3::before{
  content: '';
  display: block;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../../../assets/images/pie.png) no-repeat center 2px;
}
#titleBox .boderBottom {
  width: 100%;
  height: 4px;
  background: rgba(69, 148, 173, 1);
  background: linear-gradient(
    to right,
    rgba(69, 148, 173, 1),
    rgba(69, 148, 173, 0)
  );
  margin-top: 10px;
}
#topLeftRingEchart {
  width: 510px;
  height: 480px;
  margin: 20px auto;
}
</style>
