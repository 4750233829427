var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"centertitle",style:({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '18px',
    lineHeight: '1.5',
    color: '#ffffff',
    textAlign: 'center',
    textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
  }),attrs:{"id":"item9c61daa0-3e05-11ec-a2e6-953464dcce14"}},[_c('p',[_vm._v("按需求部门统计项目情况")])]),_vm._m(0),_c('div',{attrs:{"id":"item63811bd0-3dea-11ec-a2e6-953464dcce14"}},[_c('img',{style:({ width: '100%', height: '100%' }),attrs:{"src":require("../../../assets/images/线框.png"),"alt":""}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"leftBottomShowWindow3"}},[_c('div',{staticClass:"innerBox"},[_c('div',{attrs:{"id":"leftBottomEchartsBox31"}}),_c('div',{attrs:{"id":"leftBottomEchartsBox32"}}),_c('div',{attrs:{"id":"leftBottomEchartsBox33"}})])])
}]

export { render, staticRenderFns }