<template>
  <!-- 外框、设置背景图 -->
  <div class="container">
    <!-- 头部通栏 -->
    <div id="item88b51490-3e05-11ec-a2e6-953464dcce14">
      <!-- 头部标题 -->
      <!-- 组件4ec4a890-3dec-11ec-a2e6-953464dcce14开始-->
      <div id="item4ec4a890-3dec-11ec-a2e6-953464dcce14">
        <h1 style="color: #fff; text-align: center">国税总局采购数据大屏</h1>
      </div>
      <!-- 组件4ec4a890-3dec-11ec-a2e6-953464dcce14结束-->

      <!-- 头部通栏背景图 -->
      <!-- 组件139cfda0-3dea-11ec-a2e6-953464dcce14开始-->
      <div id="item139cfda0-3dea-11ec-a2e6-953464dcce14">
        <img src="../../assets/images/顶部栏.png" alt="" :style="{ width: '100%', height: '200%' }" />
      </div>
      <!-- 组件139cfda0-3dea-11ec-a2e6-953464dcce14结束-->
    </div>
    <!-- 左边三个控件 -->
    <div id="item8389fda0-3e05-11ec-a2e6-953464dcce14">
      <!-- 左上组件 -->
      <left-top />

      <!-- 左中组件 -->
      <left-center />

      <!-- 左下组件 -->
      <left-bottom />
    </div>

    <!-- 中间 -->
    <div id="itemc2579760-3e0d-11ec-9df4-83999b721ef6">
      <!-- 组件ea2e0360-3e07-11ec-a2e6-953464dcce14开始-->
      <div>
        <img src="../../assets/images/圆形背景.png" alt="" :style="{ width: '100%', height: '100%' }" />
      </div>
      <!-- 组件ea2e0360-3e07-11ec-a2e6-953464dcce14结束-->
    </div>
    <!-- 中间数据 -->
    <div id="contentMiddle">
      <p style="margin-right: 30px">
        {{ form[0].totalPlanAmount }}
      </p>
      <p style="margin-right: 30px; color: #53ccd7; font-size: 20px">
        预算项目总额(万元)
      </p>
    </div>
    <div style="
        position: absolute;
        display: flex;
        top: 730px;
        left: 670px;
        color: #53ccd7;
        z-index: 15;
      ">
      <div style="margin-left: 30px">
        <p style="font-size: 30px; font-weight: bold; text-align: center">{{ form[0].capitalSavingRate }}%</p>
        <p style="color: #fff; font-size: 20px">节约资金率(%)</p>
      </div>
      <div style="margin-left: 30px">
        <p style="font-size: 30px; font-weight: bold; text-align: center">
          {{ form[0].totalLastPrice }}
        </p>
        <p style="color: #fff; font-size: 20px">成交项目金额(万元)</p>
      </div>
      <div style="margin-left: 30px">
        <p style="font-size: 30px; font-weight: bold; text-align: center">
          {{ form[0].projectNums }}
        </p>
        <p style="color: #fff; font-size: 20px">项目总数(项)</p>
      </div>
    </div>
    <!-- 右边三个控件 -->
    <div id="item7a111060-3e05-11ec-a2e6-953464dcce14">
      <!-- 右上组件 -->
      <right-top />

      <!-- 右中组件 -->
      <right-center />

      <!-- 右下组件 -->
      <right-bottom />
    </div>
  </div>
</template>

<script>
import '../../assets/css/reset.css'
import '../../assets/css/custom-animation.css'
import '../../assets/css/index.css'
// 引入组件
import leftTop from './component/LeftTop'
import leftCenter from './component/LeftCenter'
import leftBottom from './component/LeftBottom'
import rightTop from './component/RightTop'
import rightCenter from './component/RightCenter'
import rightBottom from './component/RightBottom'
export default {
  data() {
    return {
      form: [
        {
          totalPlanAmount: 607176.51, // 项目总预算
          capitalSavingRate: 0.2074, // 生产总值增长率
          projectNums: 457, // 项目总数量
          totalLastPrice: 481268.65 // 项目总中标金额
        }
      ]

    }
  },
  components: {
    leftTop,
    leftCenter,
    leftBottom,
    rightTop,
    rightCenter,
    rightBottom
  },
  mounted() {
    // 组件实现出场动画
    this.$nextTick(() => {
      //  头部
      playAnimation('#item88b51490-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInDown',
          name: '向下移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      //  左
      playAnimation('#item8389fda0-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInLeft',
          name: '向右移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      // 中间
      playAnimation('#itemc2579760-3e0d-11ec-9df4-83999b721ef6', [
        {
          type: 'bounceInDown',
          name: '向下弹入',
          delayed: 0.5,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      // 右
      playAnimation('#item7a111060-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInRight',
          name: '向左移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      /**
       *
       * @param {*} id
       * @param {*} animations
       * 声明初始值
       * 判断动画是否结束
       */
      function playAnimation(id, animations) {
        let index = 0
        const ele = document.querySelector(id)
        setAnimation(ele, animations[index])
        ele.addEventListener('webkitAnimationEnd', function () {
          if (index < animations.length - 1) {
            index++
            setAnimation(ele, animations[index])
          }
        })
      }

      /**
       *
       * @param {*} element
       * @param {*} animation
       * 取index对应的动画
       * 给元素animation重新赋值
       */
      function setAnimation(element, animation) {
        const action = `${animation.duration * 1000}ms ease ${animation.delayed * 1000}ms ${animation.loop ? 'infinite' : animation.frequency
          } normal both running ${animation.type}`
        element.style.animation = action
      }
    })
    // 自适应宽度处理
    document.getElementsByClassName('container')[0].style.zoom = document.body.clientWidth / 1920
    // 窗口发生改变自动刷新页面
    function fandou(time) {
      let timeout = null
      return function () {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          location.reload()
        }, time)
      }
    }
    window.onresize = fandou(1000)
  },
  methods: {}
}
</script>

<style scoped>
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 {
  top: 150px;
  left: 546.38px;
}

#item4ec4a890-3dec-11ec-a2e6-953464dcce14 {
  left: 50%;
  width: 50%;
  font-size: 22px;
  transform: translate(-50%, 0);
  top: -2px;
}

.container {
  z-index: 9;
  background: rgba(5, 11, 22, 1) url(../../assets/images/背景.png) no-repeat;
}

.container::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url(../../assets/images/内容背景.png) no-repeat 0 80px;
  background-size: 100% 95%;
}

#contentMiddle {
  color: #fff;
  position: absolute;
  left: 566.38px;
  top: 159.7px;
  /*79.7px;*/
  width: 789.29px;
  height: 603.95px;
  /*683.95px;*/
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-shadow: 0 0 10px, 0 0 30px;
  font-size: 50px;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}
</style>
