<template>
  <div>
    <!-- 右中标题开始-->
    <div id="rightCenterTitle" class="centertitle">
      <p id="rightCenterP"><img src="../../../assets/images/plan.png" />合同付款计划统计</p>
      <div class="boderBottom"></div>
    </div>
    <!-- 右中标题结束-->

    <!-- 右中echarts开始-->
    <div id="rightCenter1">
      <div>
        <p style="margin-top: 15px; margin-left: 15px; font-size: 16px">
          付款计划金额(万元)
        </p>
        <p
          style="
            margin-top: 15px;
            margin-left: 15px;
            font-size: 20px;
            color: #53ccd7;
            font-weight: bold;
          "
        >
          {{ form.paymentPlanAmount }}
        </p>
      </div>
      <div>
        <p style="margin-top: 15px; margin-left: 15px; font-size: 16px">
          付款计划数量(项)
        </p>
        <p
          style="
            margin-top: 15px;
            margin-left: 15px;
            font-size: 20px;
            color: #53ccd7;
            font-weight: bold;
          "
        >
          {{ form.paymentPlanNumber }}
        </p>
      </div>
      <div>
        <p style="margin-top: 15px; margin-left: 15px; font-size: 16px">
          已执行付款金额(万元)
        </p>
        <p
          style="
            margin-top: 15px;
            margin-left: 15px;
            font-size: 20px;
            color: #53ccd7;
            font-weight: bold;
          "
        >
          {{ form.executedPaymentAmount }}
        </p>
      </div>
      <div>
        <p style="margin-top: 15px; margin-left: 15px; font-size: 16px">
          已执行付款数量(项)
        </p>
        <p
          style="
            margin-top: 15px;
            margin-left: 15px;
            font-size: 20px;
            color: #53ccd7;
            font-weight: bold;
          "
        >
          {{ form.executedPaymentNumber }}
        </p>
      </div>
      <div>
        <p style="margin-top: 15px; margin-left: 15px; font-size: 16px">
          合同执行完成率
        </p>
        <p
          style="
            margin-top: 15px;
            margin-left: 15px;
            font-size: 20px;
            color: #53ccd7;
            font-weight: bold;
          "
        >
          {{ form.contractCompletion }}%
        </p>
      </div>
    </div>
    <div id="rightCenter2"></div>
    <!-- 右中echarts结束-->

    <!-- 右中背景图结束-->
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { EventBus } from '../../../assets/js/eventBus.js'
export default {
  mounted () {
    EventBus.$on('rightToChild', msg => {
      this.rightCenter2Echarts()
      // A发送来的消息
      console.log('event:' + msg)
      this.rightFlag = msg
      if (this.rightFlag === 2) {
        document.querySelector('#rightCenterP').lastChild.textContent = '合同付款计划统计'
        document.getElementById('rightCenter1').style.display = 'flex'
        document.getElementById('rightCenter2').style.display = 'none'
      } else {
        document.querySelector('#rightCenterP').lastChild.textContent = '合同类型数量占比'
        document.getElementById('rightCenter1').style.display = 'none'
        document.getElementById('rightCenter2').style.display = 'block'
      }
    })
  },
  data () {
    return {
      rightFlag: '',
      form: {
        paymentPlanAmount: 52342,
        paymentPlanNumber: 5232,
        executedPaymentAmount: 2342,
        executedPaymentNumber: 5342,
        contractCompletion: 52
      },
      echartData: [
        { value: 2034, name: '单价合同' },
        { value: 25, name: '入围合同' },
        { value: 318, name: '总价合同' }
      ]
    }
  },
  methods: {
    rightCenter2Echarts () {
      var chartDom = document.getElementById('rightCenter2')
      var myChart = echarts.init(chartDom)
      var xData = []
      var yData = []
      var max = 0
      var total = this.echartData
        .map(i => {
          return i.value
        })
        .reduce((sum, i) => {
          return sum + i
        })
      var showXTxt = ''
      var echartSeries = []
      this.echartData.forEach((item, index) => {
        xData.push(item.name)
        yData.push(item.value)
        max += item.value
        if (index !== this.echartData.length - 1) {
          showXTxt += `${item.value}  ${(item.value / total).toFixed(2)}% \n`
        } else {
          showXTxt += `${item.value}  ${(item.value / total).toFixed(2)}%`
        }
        echartSeries.push({
          type: 'bar',
          name: item.name,
          barWidth: 20,
          data: [item.value],
          coordinateSystem: 'polar',
          showBackground: true
        })
      })
      var option
      option = {
        legend: {
          show: true,
          data: xData,
          top: 10,
          left: 'center',
          icon: 'rect',
          itemWidth: 14,
          itemGap: 40,
          padding: 0,
          textStyle: {
            color: '#ccc',
            fontSize: 18
          }
        },
        polar: {
          radius: [0, '100%']
        },
        color: ['#21eda5', '#edb715', '#1df2d2'],
        angleAxis: {
          max: max,
          startAngle: 90,
          splitLine: false,
          axisTick: false,
          // 轴线文字是否展示
          axisLabel: {
            interval: 0,
            inside: true,
            textStyle: {
              color: '#000',
              fontSize: 30
            },
            show: false
          },
          // 轴线是否显示
          axisLine: {
            show: false
          },
          inverse: true
        },
        radiusAxis: {
          type: 'category',
          splitLine: false,
          axisTick: false,
          data: [showXTxt],
          // 轴线是否显示
          axisLine: {
            show: false
          },
          axisLabel: {
            margin: -110,
            show: true,
            fontSize: 16,
            lineHeight: 26,
            color: '#fff'
          },
          z: 2
        },
        tooltip: {
          show: false
        },
        // [20,25,318]
        series: echartSeries
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
img {
  vertical-align: -10px;
}
#rightCenter2 {
  position: absolute;
  left: 23px;
  top: 260px;
  width: 450px;
  height: 320px;
  z-index: 5;
  display: block;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}
#rightCenter1 {
  position: absolute;
  left: 23px;
  z-index: 5;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
  top: 260px;
  height: 320px;
  width: 450px;
  display: flex;
  flex-wrap: wrap;
}
#rightCenter1 div {
  width: 210px;
  height: 100px;
  top: -10px;
  margin-left: 10px;
  background-color: #123864;
}
p {
  font-size: 16px;
  color: #fff;
}
#rightCenterTitle {
  position: absolute;
  margin-left: -150px;
  top: 200px;
}
.boderBottom {
  width: 300%;
  height: 4px;
  background: rgba(69, 148, 173, 1);
  background: linear-gradient(to right, rgba(69, 148, 173, 1), rgba(69, 148, 173, 0));
  margin-top: 10px;
}
</style>
