<template>
  <div>
    <!-- 右下标题开始-->
    <div
      id="itemd0cac130-3e05-11ec-a2e6-953464dcce14"
      :style="{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        lineHeight: '1.5',
        color: '#ffffff',
        textAlign: 'center',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
      }"
      class="centertitle"
    >
      <p>供应商项目数量占比情况</p>
    </div>
    <!-- 右下标题结束-->

    <!-- 右下echarts开始-->
    <div id="item93b724c0-3e03-11ec-a2e6-953464dcce14">
      <table rules="none" class="projectNumTable">
        <thead>
          <tr>
            <th>序号</th>
            <th>企业名称</th>
            <th>占比</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in showData" :key="item.companyName">
            <td>{{ item.no }}</td>
            <td>{{ item.companyName }}</td>
            <td>{{ item.companyPencent }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- 右下echarts结束-->

    <!-- 右下背景图开始-->
    <div id="item71b4b580-3deb-11ec-a2e6-953464dcce14">
      <img src="../../../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
    </div>
    <!-- 右下背景图结束-->
  </div>
</template>

<script>
export default {
  mounted () {
    this.dealWithData()
  },
  data () {
    return {
      // 模拟后端传来的数据
      resData: [
        {
          companyName: '神州数码信息系统有限公司', // 厂商名称
          companyPencent: 0.046, // 厂商项目数量占比
          projectNums: 21 // 厂商项目数量
        },
        {
          companyName: '中国软件与技术服务股份有限公司',
          companyPencent: 0.0438,
          projectNums: 20
        },
        {
          companyName: '北京华宇信息技术有限公司',
          companyPencent: 0.0372,
          projectNums: 17
        },
        {
          companyName: '税友软件集团股份有限公司',
          companyPencent: 0.035,
          projectNums: 16
        },
        {
          companyName: '长城计算机软件与系统有限公司',
          companyPencent: 0.0306,
          projectNums: 14
        },
        {
          companyName: '京瓷办公信息系统（中国）有限公司',
          companyPencent: 0.0263,
          projectNums: 12
        },
        {
          companyName: '联想（北京）有限公司',
          companyPencent: 0.0219,
          projectNums: 10
        }
      ],
      showData: [] // 用于展示的数据
    }
  },
  methods: {
    // 处理接收数据，用于展示
    dealWithData () {
      this.resData.forEach((item, index) => {
        this.showData.push({
          no: index + 1,
          companyName: item.companyName,
          companyPencent: Number(item.companyPencent * 100).toFixed(2) + '%',
          projectNums: item.projectNums
        })
      })
    }
  }
}
</script>

<style scoped>
/* 背景图位置及大小 */
#item71b4b580-3deb-11ec-a2e6-953464dcce14 {
  top: 640px;
  left: 20px;
  width: 530px;
  height: 310px;
}
/* 标题位置 */
#itemd0cac130-3e05-11ec-a2e6-953464dcce14 {
  top: 654px;
}
#item93b724c0-3e03-11ec-a2e6-953464dcce14 {
  top: 694px;
}
.projectNumTable {
  width: 100%;
  height: 86%;
  font-size: 16px;
  color: #fff;
}

.projectNumTable thead tr th:nth-child(1) {
  width: 60px;
}
.projectNumTable thead tr th:nth-child(2) {
  text-align: left;
}
.projectNumTable tbody tr td {
  text-align: center;
}
.projectNumTable tbody tr td:nth-child(2) {
  text-align: left;
}
.projectNumTable tbody tr {
  background: linear-gradient(to right, rgba(28, 103, 161, 1), rgba(28, 103, 161, 0.1));
}
.projectNumTable tbody tr:nth-child(2n) {
  background: linear-gradient(to right, rgba(4, 36, 94, 1), rgba(4, 36, 94, 0.1));
}
</style>
