import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
import Home from '../pages/Home.vue';
import ScreenDesign1 from '../pages/ScreenDesign1.vue';
import ScreenDesign2 from '../pages/ScreenDesign2/ScreenDesign2.vue';
import ScreenDesign3 from '../pages/ScreenDesign3/ScreenDesign3.vue';
import ScreenDesign4 from '../pages/ScreenDesign4/ScreenDesign4.vue';
import ScreenDesign5 from '../pages/ScreenDesign5/ScreenDesign5.vue';

const routes = [
  { path: '/home', component: Home, name:'home' },
  { path: '/ScreenDesign1', component: ScreenDesign1, name:'ScreenDesign1' },
  { path: '/ScreenDesign2', component: ScreenDesign2, name:'ScreenDesign2' },
  { path: '/ScreenDesign3', component: ScreenDesign3, name:'ScreenDesign3' },
  { path: '/ScreenDesign4', component: ScreenDesign4, name:'ScreenDesign4' },
  { path: '/ScreenDesign5', component: ScreenDesign5, name:'ScreenDesign5' },
  { path: '/', redirect: '/home' }   /*默认跳转路由*/
]

const router = new VueRouter({
  mode: 'history', 
  routes 
})

export default router;