var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"centertitle",style:({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '18px',
      lineHeight: '1.5',
      color: '#ffffff',
      textAlign: 'center',
      textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
    }),attrs:{"id":"itemd00a0940-3e05-11ec-a2e6-953464dcce14"}},[_c('p',[_vm._v("厂商签约项目数量及金额情况")])]),_c('div',{attrs:{"id":"item40b1de80-3e05-11ec-a2e6-953464dcce14"}}),_c('div',{attrs:{"id":"item677df680-3deb-11ec-a2e6-953464dcce14"}},[_c('img',{style:({ width: '100%', height: '100%' }),attrs:{"src":require("../../../assets/images/线框.png"),"alt":""}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }