var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"centertitle",style:({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '18px',
      lineHeight: '1.5',
      color: '#ffffff',
      textAlign: 'center',
      textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
    }),attrs:{"id":"item9c61daa0-3e05-11ec-a2e6-953464dcce14"}},[_c('h2',[_vm._v("合同数据分析")])]),_c('div',{attrs:{"id":"item62c8d410-3e05-11ec-a2e6-953464dcce14"}},[_c('span',{staticStyle:{"margin-left":"150px"},on:{"click":_vm.flag1}},[_vm._v("合同签订")]),_c('span',{staticStyle:{"margin-left":"120px"},on:{"click":_vm.flag2}},[_vm._v("合同执行")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }