<template>
  <div>
    <!-- 右上标题开始-->
    <div id="item9c61daa0-3e05-11ec-a2e6-953464dcce14" :style="{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '18px',
      lineHeight: '1.5',
      color: '#ffffff',
      textAlign: 'center',
      textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
    }" class="centertitle">
      <p>按需求部门统计项目情况</p>
    </div>
    <!-- 右上标题结束-->

    <!-- 右上echarts开始-->
    <!-- <div id="item62c8d410-3e05-11ec-a2e6-953464dcce14"></div> -->
    <div id="leftBottomShowWindow3">
      <div class="innerBox">
        <div id="leftBottomEchartsBox31"></div>
        <div id="leftBottomEchartsBox32"></div>
        <div id="leftBottomEchartsBox33"></div>
      </div>
    </div>
    <!-- 右上echarts结束-->

    <!-- 右上背景图开始-->
    <div id="item63811bd0-3dea-11ec-a2e6-953464dcce14">
      <img src="../../../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
    </div>
    <!-- 右上背景图结束-->
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  mounted() {
    this.setEcharts('leftBottomEchartsBox31', 0)
    this.setEcharts('leftBottomEchartsBox32', 1)
    this.setEcharts('leftBottomEchartsBox33', 0)
  },
  data() {
    return {
      data1: [2.0, 4.9, 7.0, 23.2, 25.6],
      data2: [2.6, 5.9, 9.0, 26.4, 28.7],
      data3: [2.6, 5.9, 9.0, 26.4, 28.7],
      data4: [2.0, 2.2, 3.3, 4.5, 6.3],
      // 模拟数据
      resData: [
        {
          totalPlanAmount: 59368.19, // 需求部门对应项目总预算
          capitalSavingRate: 0.1976, // 需求部门对应生产总值增长率
          recorderOrgName: '电子税务管理中心', // 需求部门名称
          projectNums: 109, // 需求部门对应项目数量
          totalLastPrice: 47635.12 // 需求部门对应项目总中标金额
        },
        {
          totalPlanAmount: 209064.71,
          capitalSavingRate: 0.0412,
          recorderOrgName: '征管科技司',
          projectNums: 64,
          totalLastPrice: 200448.45
        },
        {
          totalPlanAmount: 43262.6,
          capitalSavingRate: 0.4126,
          recorderOrgName: '办公厅',
          projectNums: 32,
          totalLastPrice: 25411.07
        },
        {
          totalPlanAmount: 6067.22,
          capitalSavingRate: 0.1362,
          recorderOrgName: '服务中心',
          projectNums: 18,
          totalLastPrice: 5241.09
        },
        {
          totalPlanAmount: 40074.05,
          capitalSavingRate: 0.4384,
          recorderOrgName: '电子税务中心',
          projectNums: 18,
          totalLastPrice: 22507.33
        }
      ]
    }
  },
  methods: {
    /*
      创建echarts图
      @param id: 要画echarts的容器
      @param flag: 统计项目的内容
      - flag = 1 : 统计内容为项目数量柱状图
      - flag = 0 : 统计内容为招商金额柱状图、中标金额柱状图和生产总值增长率折线图
    */
    setEcharts(id, flag) {
      // 根据flag不同定义不同的数据用来echarts的展示
      const showEchartsData = flag
        ? [
          {
            name: '项目数量',
            type: 'bar',
            barMaxWidth: 30, // 柱图宽度
            data: []
          }
        ]
        : [
          {
            name: '招商金额',
            type: 'bar',
            barMaxWidth: 30, // 柱图宽度
            data: []
          },
          {
            name: '中标金额',
            type: 'bar',
            barMaxWidth: 30,
            data: []
          },
          {
            name: '生产总值增长率',
            type: 'line',
            yAxisIndex: 1,
            data: []
          }
        ]
      const xData = []
      this.resData.forEach((item) => {
        xData.push(item.recorderOrgName)
        if (flag) {
          showEchartsData[0].data.push(item.projectNums)
        } else {
          showEchartsData[0].data.push(item.totalPlanAmount)
          showEchartsData[1].data.push(item.totalLastPrice)
          showEchartsData[2].data.push(Number(item.capitalSavingRate * 100).toFixed(2))
        }
      })
      var chartDom = document.getElementById(id)
      var myChart = echarts.init(chartDom)
      var option
      option = {
        legend: {
          data: flag ? ['项目数量'] : ['招商金额', '中标金额', '生产总值增长率'],
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: { interval: 0 }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: flag ? '项目数量' : '招商金额',
            axisLabel: {
              formatter: '{value}',
              rotate: flag ? '0' : '45' // y轴字体倾斜角度
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#375C77'
              }
            }
          },
          {
            type: 'value',
            name: flag ? '' : '生产总值增长率',
            axisLabel: {
              formatter: flag ? '' : '{value}%'
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: showEchartsData
      }

      option && myChart.setOption(option)
    },
    /*
      banner滚动
      @param n: 当前展示的第几张图，从0开始
      @param bufferMove: 函数(能够实现元素的减速运动)
    */
    changeBanner(n, bufferMove) {
      const bannerWrap = document.querySelector('#leftBottomShowWindow3 .innerBox')
      if (n === -1) {
        bannerWrap.style.left = '-1000px'
        n = 1
      }
      bufferMove(
        bannerWrap,
        {
          left: -n * 500
        },
        30
      )
    }
  }
}
</script>

<style scoped>
/* 背景图位置及大小 */
#item63811bd0-3dea-11ec-a2e6-953464dcce14 {
  left: 20px;
  width: 530px;
  height: 310px;
}

/* 标题位置 */
#item9c61daa0-3e05-11ec-a2e6-953464dcce14 {
  top: 14px;
}

#leftBottomShowWindow3 {
  position: absolute;
  left: 35px;
  top: 60px;
  width: 500px;
  height: 270px;
  overflow: hidden;
}

#leftBottomShowWindow3 .innerBox {
  display: flex;
  position: absolute;
  top: 0;
  left: -1000px;
  height: 270px;
  width: 1350px;
}

#leftBottomShowWindow3 .innerBox div {
  position: absolute;
  top: 0px;
  width: 500px;
  height: 270px;
  z-index: 8;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}

#leftBottomEchartsBox31 {
  left: 0px;
}

#leftBottomEchartsBox32 {
  left: 500px;
}

#leftBottomEchartsBox33 {
  left: 1000px;
}
</style>
