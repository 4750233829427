<template>
  <div>
    <!-- 右中标题开始-->
    <div
      id="itemd00a0940-3e05-11ec-a2e6-953464dcce14"
      :style="{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        lineHeight: '1.5',
        color: '#ffffff',
        textAlign: 'center',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
      }"
      class="centertitle"
    >
      <p>厂商签约项目数量及金额情况</p>
    </div>
    <!-- 右中标题结束-->

    <!-- 右中echarts开始-->
    <div id="item40b1de80-3e05-11ec-a2e6-953464dcce14"></div>
    <!-- 右中echarts结束-->

    <!-- 右中背景图开始-->
    <div id="item677df680-3deb-11ec-a2e6-953464dcce14">
      <img src="../../../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
    </div>
    <!-- 右中背景图结束-->
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  mounted () {
    this.setEcharts()
  },
  data () {
    return {
      resData: [
        {
          companyName: '神州数码信息系统有限公司', // 厂商名称
          projectNums: 21, // 厂商项目数量
          totalLastPrice: 48357.94 // 厂商中标金额
        },
        {
          companyName: '中国软件与技术服务股份有限公司',
          projectNums: 20,
          totalLastPrice: 99697.99
        },
        {
          companyName: '税友软件集团股份有限公司',
          projectNums: 16,
          totalLastPrice: 76496.87
        },
        {
          companyName: '北京华宇信息技术有限公司',
          projectNums: 14,
          totalLastPrice: 4987.05
        },
        {
          companyName: '长城计算机软件与系统有限公司',
          projectNums: 13,
          totalLastPrice: 5084.62
        },
        {
          companyName: '京瓷办公信息系统（中国）有限公司',
          projectNums: 12,
          totalLastPrice: 4105.71
        },
        {
          companyName: '联想（北京）有限公司',
          projectNums: 10,
          totalLastPrice: 21272.83
        }
      ]
    }
  },
  methods: {
    setEcharts () {
      // 对数据进行处理
      const showEchartsData = [
        {
          name: '签约项目数量',
          type: 'bar',
          barMaxWidth: 30, // 柱图宽度
          data: []
        },
        {
          name: '签约总金额',
          type: 'line',
          yAxisIndex: 1,
          data: []
        }
      ]
      const xData = []
      this.resData.forEach((item) => {
        xData.push(item.companyName)
        showEchartsData[0].data.push(item.projectNums)
        showEchartsData[1].data.push(item.totalLastPrice)
      })

      var chartDom = document.getElementById('item40b1de80-3e05-11ec-a2e6-953464dcce14')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        legend: {
          data: ['签约项目数量', '签约总金额'],
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          // 调整echact右边和下边的距离，用于显示名字的全部
          right: '20%',
          bottom: 80
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: { interval: 0, rotate: '-20' }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: '签约项目数量',
            axisLabel: {
              formatter: '{value}'
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#375C77'
              }
            }
          },
          {
            type: 'value',
            name: '签约总金额',
            axisLabel: {
              formatter: '{value}万元',
              rotate: '20'
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: showEchartsData
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
/* 背景位置及大小 */
#item677df680-3deb-11ec-a2e6-953464dcce14 {
  top: 320px;
  left: 20px;
  width: 530px;
  height: 310px;
}
/* 标题位置 */
#itemd00a0940-3e05-11ec-a2e6-953464dcce14 {
  top: 334px;
}
#item40b1de80-3e05-11ec-a2e6-953464dcce14 {
  top: 370px;
  width: 520px;
  height: 260px;
}
</style>
