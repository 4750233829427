<template>
  <div>
    <!-- 左中标题头 -->
    <!-- 组件1550b630-3dec-11ec-a2e6-953464dcce14开始-->
    <div id="leftCenter1550b630-3dec-11ec-a2e6-953464dcce14" class="centertitle">
      <p id="leftCenterP"><img src="../../../assets/images/rank.png" />成交数量排名</p>
      <div class="boderBottom"></div>
    </div>
    <span
      id="leftSpan"
      style="
        color: #6d747e;
        position: fixed;
        left: 420px;
        top: 200px;
        font-size: 20px;
      ">单位：项</span
    >
    <!-- 组件1550b630-3dec-11ec-a2e6-953464dcce14结束-->
    <!-- 左中echarts图展示区域 -->
    <!-- 组件a1c42d00-3deb-11ec-a2e6-953464dcce14开始-->
    <div id="leftCenter1" style="display: none"></div>
    <div id="leftCenter2" style="display: none"></div>
    <div id="leftCenter3" style="display: none"></div>
    <div id="leftCenter4"></div>

    <!-- 组件0c892655-9dbb-497c-bec9-239cca87c34a结束-->
  </div>
</template>

<script>
import { EventBus } from '../../../assets/js/eventBus.js'
import * as echarts from 'echarts'
export default {
  mounted () {
    this.leftCenter2Echarts()
    this.leftCenter1Echarts()
    this.leftCenter3Echarts()
    this.leftCenter4Echarts()
    EventBus.$on('leftToChild', msg => {
      // A发送来的消息
      console.log('event:' + msg)
      this.leftFlag = msg
      if (this.leftFlag === 2) {
        document.querySelector('.boderBottom').style.width = '300%'
        document.getElementById('leftSpan').style.display = 'none'
        document.getElementById('leftCenterP').lastChild.textContent = '组织形式数量占比'
        document.getElementById('leftCenter1').style.display = 'none'
        document.getElementById('leftCenter2').style.display = 'block'
        document.getElementById('leftCenter3').style.display = 'none'
        document.getElementById('leftCenter4').style.display = 'none'
      } else if (this.leftFlag === 3) {
        document.querySelector('.boderBottom').style.width = '350%'
        document.getElementById('leftSpan').style.display = 'inline'
        document.getElementById('leftCenterP').lastChild.textContent = '项目数量排名'
        document.getElementById('leftCenter1').style.display = 'none'
        document.getElementById('leftCenter2').style.display = 'none'
        document.getElementById('leftCenter3').style.display = 'block'
        document.getElementById('leftCenter4').style.display = 'none'
      } else if (this.leftFlag === 4) {
        document.querySelector('.boderBottom').style.width = '350%'
        document.getElementById('leftSpan').style.display = 'inline'
        document.getElementById('leftCenterP').lastChild.textContent = '成交数量排名'
        document.getElementById('leftCenter1').style.display = 'none'
        document.getElementById('leftCenter2').style.display = 'none'
        document.getElementById('leftCenter3').style.display = 'none'
        document.getElementById('leftCenter4').style.display = 'block'
      } else {
        document.querySelector('.boderBottom').style.width = '300%'
        document.getElementById('leftSpan').style.display = 'none'
        document.getElementById('leftCenterP').lastChild.textContent = '采购方式数量占比'
        document.getElementById('leftCenter1').style.display = 'block'
        document.getElementById('leftCenter2').style.display = 'none'
        document.getElementById('leftCenter3').style.display = 'none'
        document.getElementById('leftCenter4').style.display = 'none'
      }
    })
  },
  data () {
    return {
      leftFlag: '',
      echartsData1: [
          {
            purchaseWay: '竞争性磋商',
            purchaseWayPencent: 0.1357,
            projectNums: 62
          },
          {
            purchaseWay: '公开招标',
            purchaseWayPencent: 0.5339,
            projectNums: 244
          },
          {
            purchaseWay: '单一来源',
            purchaseWayPencent: 0.1313,
            projectNums: 60
          },
          {
            purchaseWay: '协议供货',
            purchaseWayPencent: 0.0306,
            projectNums: 14
          },
          {
            purchaseWay: '批量采购',
            purchaseWayPencent: 0.0438,
            projectNums: 20
          },
          {
            purchaseWay: '电子卖场',
            purchaseWayPencent: 0.0044,
            projectNums: 2
          },
          {
            purchaseWay: '竞争性谈判',
            purchaseWayPencent: 0.0438,
            projectNums: 20
          },
          {
            purchaseWay: '定点采购',
            purchaseWayPencent: 0.0109,
            projectNums: 5
          },
          {
            purchaseWay: '审核入围',
            purchaseWayPencent: 0.0525,
            projectNums: 24
          },
          {
            purchaseWay: '邀请招标',
            purchaseWayPencent: 0.0066,
            projectNums: 3
          },
          {
            purchaseWay: '询价',
            purchaseWayPencent: 0.0044,
            projectNums: 2
          },
          {
            purchaseWay: '网上竞价',
            purchaseWayPencent: 0.0022,
            projectNums: 1
          }
        ],
      echartsData2: [
	{
		wayType: '自行组织', // 组织形式名称
		projectNums: 363, // 项目数量
		wayTypePencent: 0.7943 // 占比
	},
	{
		wayType: '委托国采',
		projectNums: 77,
		wayTypePencent: 0.1685
	},
	{
		wayType: '委托代理机构',
		projectNums: 10,
		wayTypePencent: 0.0219
	}
],
      echartsData3: [
	{
		recorderOrgName: '电子税务管理中心', // 需求部门名称
		projectNums: 137 // 项目数量
	},
	{
		recorderOrgName: '征管科技司',
		projectNums: 71
	},
	{
		recorderOrgName: '办公厅',
		projectNums: 32
	},
	{
		recorderOrgName: '税务系统',
		projectNums: 29
	},
	{
		recorderOrgName: '电子税务中心',
		projectNums: 18
	},
	{
		recorderOrgName: '服务中心',
		projectNums: 18
	},
	{
		recorderOrgName: '财务管理司',
		projectNums: 15
	},
	{
		recorderOrgName: '国税系统',
		projectNums: 12
	},
	{
		recorderOrgName: '宣传中心',
		projectNums: 11
	},
	{
		recorderOrgName: '货物劳务税司',
		projectNums: 11
	}
],
 echartsData4: [
	{
		companyName: '神州数码信息系统有限公司',
		projectNums: 21
	},
	{
		companyName: '中国软件与技术服务股份有限公司',
		projectNums: 20
	},
	{
		companyName: '北京华宇信息技术有限公司',
		projectNums: 17
	},
	{
		companyName: '税友软件集团股份有限公司',
		projectNums: 16
	},
	{
		companyName: '长城计算机软件与系统有限公司',
		projectNums: 14
	},
	{
		companyName: '京瓷办公信息系统（中国）有限公司',
		projectNums: 12
	},
	{
		companyName: '联想（北京）有限公司',
		projectNums: 10
	},
	{
		companyName: '东软集团股份有限公司',
		projectNums: 10
	},
	{
		companyName: '北京银信长远科技股份有限公司',
		projectNums: 9
	},
	{
		companyName: '同方股份有限公司',
		projectNums: 8
	}
]
    }
  },
  methods: {
    leftCenter4Echarts () {
      var chartDom = document.getElementById('leftCenter4')
      var myChart = echarts.init(chartDom)
      var getName = this.echartsData4.map(value => {
        return value.companyName
      })
      var getValue = this.echartsData4.map(value => {
        return value.projectNums
      })
      getValue.sort((a, b) => b - a)
      var value = getValue.map(i => {
        return -i
      })
      var option = {
        grid: {
          left: '8%',
          right: '2%',
          bottom: '2%',
          top: '2%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          formatter: function (params) {
            return params[0].name + ' : ' + -params[0].value
          }
        },
        xAxis: {
          show: false
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            offset: 140,
            positon: 'left',
            interval: 10,
            triggerEvent: true,
            axisLabel: {
              show: true,
              align: 'left',
              textStyle: {
                color: '#66cc00'
              },
              formatter: function (item, index) {
                var str = ''
                var value = item
                var num = index + 1
                if (item.length >= 10) {
                  value = item.slice(0, 9) + '...'
                }
                if (index === 0) {
                  str = '{num1|' + num + '} {title1|' + value + '}'
                } else if (index === 1) {
                  str = '{num2|' + num + '} {title2|' + value + '}'
                } else if (index === 2) {
                  str = '{num3|' + num + '} {title3|' + value + '}'
                } else {
                  str = '{num|' + num + '} {title|' + value + '}'
                }

                return str
              },
              rich: {
                num: {
                  color: '#fff',
                  backgroundColor: '#8e8989',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num1: {
                  color: '#fff',
                  backgroundColor: '#ff4d4d',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num2: {
                  color: '#fff',
                  backgroundColor: '#4dd2ff',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num3: {
                  color: '#fff',
                  backgroundColor: '#ffff00',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                title: {
                  color: '#bfb7b7'
                },
                title1: {
                  color: '#ff4d4d'
                },
                title2: {
                  color: '#4dd2ff'
                },
                title3: {
                  color: '#ffff00'
                }
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                width: 2,
                color: '#586fa0'
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: getName
          },
          {
            type: 'category',
            inverse: true,
            offset: 0,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#81e4ff',
                fontSize: '20'
              }
            },
            data: getValue
          }
        ],
        series: [
          {
            name: '值',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: 'rgb(128,204,255,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(46,200,207,1)'
                  }
                ])
              }
            },
            barWidth: 15,
            data: value
          }
        ]
      }
      option && myChart.setOption(option)
    },
    leftCenter1Echarts () {
      const myChart = echarts.init(document.getElementById('leftCenter1'))
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: '采购方式 <br/>{b} : {c} ({d}%)'
        },
        /*
        legend: {
          left: '20px',
          top: 10,
          icon: 'stack',
          itemGap: 18,
          itemWidth: 18,
          textStyle: { fontSize: 18, color: '#bfb7b7' }
        },
        */
        color: ['#5dcafd', '#05eca7', '#fdbd2c', '#f27b72'],
        series: [
          {
            name: 'Nightingale Chart',
            type: 'pie',
            radius: ['40%', '70%'],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 0
            },
            data: [
              {
                value: this.echartsData1[0].projectNums,
                name: this.echartsData1[0].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#5dcafd'
                }
              },
              {
                value: this.echartsData1[1].projectNums,
                name: this.echartsData1[1].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#05eca7'
                }
              },
              {
                value: this.echartsData1[2].projectNums,
                name: this.echartsData1[2].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#fdbd2c'
                }
              },
              {
                value: this.echartsData1[3].projectNums,
                name: this.echartsData1[3].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#f27b72'
                }
              },
              {
                value: this.echartsData1[4].projectNums,
                name: this.echartsData1[4].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#5dcafd'
                }
              },
              {
                value: this.echartsData1[5].projectNums,
                name: this.echartsData1[5].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#05eca7'
                }
              },
              {
                value: this.echartsData1[6].projectNums,
                name: this.echartsData1[6].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#fdbd2c'
                }
              },
              {
                value: this.echartsData1[7].projectNums,
                name: this.echartsData1[7].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#f27b72'
                }
              },
              {
                value: this.echartsData1[8].projectNums,
                name: this.echartsData1[8].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#5dcafd'
                }
              },
              {
                value: this.echartsData1[9].projectNums,
                name: this.echartsData1[9].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#05eca7'
                }
              },
              {
                value: this.echartsData1[10].projectNums,
                name: this.echartsData1[10].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#fdbd2c'
                }
              },
              {
                value: this.echartsData1[11].projectNums,
                name: this.echartsData1[11].purchaseWay,
                label: {
                  fontSize: '20px',
                  color: '#f27b72'
                }
              }
            ],
            labelLine: {
              normal: {
                show: true,
                length: 20,
                length2: 50,
                lineStyle: {
                  color: '#d3d3d3'
                },
                align: 'right'
              }
            },
            label: {
              normal: {
                padding: [0, -30],
                formatter: '{c|{c}}\n{d|{d}%}',
                rich: {
                  d: {
                    fontSize: '18px',
                    align: 'center',
                    color: '#fff'
                  }
                }
              }
            }
          }
        ]
      }
      myChart.setOption(option)
    },
    leftCenter2Echarts () {
      const myChart = echarts.init(document.getElementById('leftCenter2'))

      var option = {
        legend: {
          left: '70px',
          top: 10,
          icon: 'stack',
          itemGap: 16,
          itemWidth: 16,
          textStyle: { fontSize: 16, color: '#bfb7b7' }
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {d}% <br/> {c}'
        },
        color: ['#0ef45b', '#00FFFF', '#f49e13', '#f27b72', '#e84a22'],
        series: [
          {
            type: 'pie',
            radius: ['30%', '50%'],
            center: ['50%', '50%'],
            data: [
              {
                value: this.echartsData2[0].projectNums,
                label: {
                  fontSize: '20px',
                  color: '#0ef45b'
                },
                name: this.echartsData2[0].wayType
              },
              {
                value: this.echartsData2[1].projectNums,
                label: {
                  fontSize: '20px',
                  color: '#00FFFF'
                },
                name: this.echartsData2[1].wayType
              },
              {
                value: this.echartsData2[2].projectNums,
                label: {
                  fontSize: '20px',
                  color: '#f49e13'
                },
                name: this.echartsData2[2].wayType
              }
            ],
            labelLine: {
              normal: {
                show: true,
                length: 20,
                length2: 45,
                lineStyle: {
                  color: '#d3d3d3'
                },
                align: 'right'
              }
            },
            label: {
              normal: {
                formatter: '{c|{c}}\n{d|{d}%}',
                padding: [0, -20],
                rich: {
                  d: {
                    fontSize: '18px',
                    align: 'center',
                    color: '#fff'
                  }
                }
              }
            }
          }
        ]
      }
      myChart.setOption(option)
    },
    leftCenter3Echarts () {
      const myChart = echarts.init(document.getElementById('leftCenter3'))
      var getName = this.echartsData3.map(value => {
        return value.recorderOrgName
      })
      var getValue = this.echartsData3.map(value => {
        return value.projectNums
      })
      var value = getValue.map(i => {
        return -i
      })
      var option = {
        grid: {
          left: '8%',
          right: '2%',
          bottom: '2%',
          top: '2%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          formatter: function (params) {
            return params[0].name + ' : ' + -params[0].value
          }
        },
        xAxis: {
          show: false
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            offset: 140,
            positon: 'left',
            interval: 10,
            triggerEvent: true,
            axisLabel: {
              show: true,
              align: 'left',
              textStyle: {
                color: '#66cc00'
              },
              formatter: function (item, index) {
                var str = ''
                var value = item
                var num = index + 1
                if (item.length >= 10) {
                  value = item.slice(0, 9) + '...'
                }
                if (index === 0) {
                  str = '{num1|' + num + '} {title1|' + value + '}'
                } else if (index === 1) {
                  str = '{num2|' + num + '} {title2|' + value + '}'
                } else if (index === 2) {
                  str = '{num3|' + num + '} {title3|' + value + '}'
                } else {
                  str = '{num|' + num + '} {title|' + value + '}'
                }

                return str
              },
              rich: {
                num: {
                  color: '#fff',
                  backgroundColor: '#8e8989',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num1: {
                  color: '#fff',
                  backgroundColor: '#ff4d4d',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num2: {
                  color: '#fff',
                  backgroundColor: '#4dd2ff',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num3: {
                  color: '#fff',
                  backgroundColor: '#ffff00',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                title: {
                  color: '#bfb7b7'
                },
                title1: {
                  color: '#ff4d4d'
                },
                title2: {
                  color: '#4dd2ff'
                },
                title3: {
                  color: '#ffff00'
                }
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                width: 2,
                color: '#586fa0'
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: getName
          },
          {
            type: 'category',
            inverse: true,
            offset: 0,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#81e4ff',
                fontSize: '20'
              }
            },
            data: getValue
          }
        ],
        series: [
          {
            name: '值',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: 'rgb(128,204,255,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(46,200,207,1)'
                  }
                ])
              }
            },
            barWidth: 15,
            data: value
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
.boderBottom {
  width: 300%;
  height: 4px;
  background: rgba(69, 148, 173, 1);
  background: linear-gradient(to right, rgba(69, 148, 173, 1), rgba(69, 148, 173, 0));
  margin-top: 10px;
}
p {
  font-size: 16px;
  color: #fff;
}
img {
  vertical-align: -10px;
}
#leftCenter1550b630-3dec-11ec-a2e6-953464dcce14 {
  position: absolute;
  left: 238.33px;
  top: 200px;
  margin-left: -150px;
  width: 400px;
  height: 50px;
  z-index: 7;
  display: block;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}
#leftCenter2,
#leftCenter1,
#leftCenter3,
#leftCenter4 {
  position: absolute;
  left: 47.96px;
  top: 260px;
  width: 450px;
  height: 320px;
  z-index: 5;
  display: block;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}
</style>
