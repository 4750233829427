var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"centertitle",style:({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '18px',
      lineHeight: '1.5',
      color: '#ffffff',
      textAlign: 'center',
      textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
    }),attrs:{"id":"item05cd9ca0-3dec-11ec-a2e6-953464dcce14"}},[_c('p',[_vm._v("项目采购方式占比情况")])]),_c('div',{attrs:{"id":"item8d374050-3e10-11ec-b331-d9ec61910d4a"}}),_c('div',{attrs:{"id":"itemda42a880-eaf0-4a65-b71f-a313e2bd4daf"}},[_c('img',{style:({ width: '100%', height: '100%' }),attrs:{"src":require("../../../assets/images/线框.png"),"alt":""}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }