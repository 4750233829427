<template>
  <!-- 下部开始 -->
  <div id="bottomWrap">
    <table class="tableLeft" rules="none">
      <thead>
        <tr>
          <th>序号</th>
          <th>项目名称</th>
          <th>技术部门</th>
          <th>项目金额(万元)</th>
          <th class="progress">意向阶段</th>
          <th class="progress">需求阶段</th>
          <th class="progress">计划阶段</th>
          <th class="progress">采购文件</th>
          <th class="progress">采购公告</th>
          <th class="progress">开评标</th>
          <th class="progress">确认采购结果</th>
          <th class="progress">采购结果公告</th>
          <th class="progress">签订合同</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in resData" :key="item.projectId">
          <td>{{ item.no }}</td>
          <td>{{ item.projectName }}</td>
          <td>{{ item.companyName }}</td>
          <td>{{ item.companyPencent }}</td>
          <td v-for="i in item.plan" :key="i.planPhase">
            <div :class="i.planStatus === '03' ? '' : 'activeProgress'"></div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- <div id="echartBar4"></div> -->
  </div>
  <!-- 下部结束 -->
</template>

<script>
export default {
  mounted () {},
  data () {
    return {
      // 模拟数据
      resData: [
        {
          no: 1,
          projectId: '00001',
          projectName: 'XXX采购项目1',
          companyName: '税务所',
          companyPencent: '142.30',
          plan: [
            { planPhase: '意向阶段', planTime: '04', planStatus: '01' },
            { planPhase: '需求阶段', planTime: '06', planStatus: '01' },
            { planPhase: '计划阶段', planTime: '10', planStatus: '01' },
            { planPhase: '采购文件', planTime: '13', planStatus: '02' },
            { planPhase: '采购公告', planTime: '16', planStatus: '03' },
            { planPhase: '开评标', planTime: '18', planStatus: '03' },
            { planPhase: '评审结果', planTime: '21', planStatus: '03' },
            { planPhase: '结果公告', planTime: '23', planStatus: '03' },
            { planPhase: '签订合同', planTime: '25', planStatus: '03' }
          ]
        },
        {
          no: 2,
          projectId: '00002',
          projectName: 'XXX采购项目2',
          companyName: '征科司',
          companyPencent: '132.40',
          plan: [
            { planPhase: '意向阶段', planTime: '02', planStatus: '01' },
            { planPhase: '需求阶段', planTime: '05', planStatus: '01' },
            { planPhase: '计划阶段', planTime: '08', planStatus: '01' },
            { planPhase: '采购文件', planTime: '11', planStatus: '01' },
            { planPhase: '采购公告', planTime: '14', planStatus: '01' },
            { planPhase: '开评标', planTime: '20', planStatus: '02' },
            { planPhase: '评审结果', planTime: '24', planStatus: '03' },
            { planPhase: '结果公告', planTime: '27', planStatus: '03' },
            { planPhase: '签订合同', planTime: '30', planStatus: '03' }
          ]
        },
        {
          no: 3,
          projectId: '00003',
          projectName: 'XXX采购项目3',
          companyName: '征科司',
          companyPencent: '231.40',
          plan: [
            { planPhase: '意向阶段', planTime: '03', planStatus: '01' },
            { planPhase: '需求阶段', planTime: '07', planStatus: '01' },
            { planPhase: '计划阶段', planTime: '12', planStatus: '02' },
            { planPhase: '采购文件', planTime: '15', planStatus: '03' },
            { planPhase: '采购公告', planTime: '17', planStatus: '03' },
            { planPhase: '开评标', planTime: '19', planStatus: '03' },
            { planPhase: '评审结果', planTime: '22', planStatus: '03' },
            { planPhase: '结果公告', planTime: '24', planStatus: '03' },
            { planPhase: '签订合同', planTime: '26', planStatus: '03' }
          ]
        },
        {
          no: 4,
          projectId: '00004',
          projectName: 'XXX采购项目4',
          companyName: '征科司',
          companyPencent: '431.20',
          plan: [
            { planPhase: '意向阶段', planTime: '04', planStatus: '01' },
            { planPhase: '需求阶段', planTime: '06', planStatus: '01' },
            { planPhase: '计划阶段', planTime: '10', planStatus: '01' },
            { planPhase: '采购文件', planTime: '13', planStatus: '01' },
            { planPhase: '采购公告', planTime: '16', planStatus: '01' },
            { planPhase: '开评标', planTime: '18', planStatus: '01' },
            { planPhase: '评审结果', planTime: '21', planStatus: '01' },
            { planPhase: '结果公告', planTime: '23', planStatus: '02' },
            { planPhase: '签订合同', planTime: '25', planStatus: '03' }
          ]
        },

        {
          no: 5,
          projectId: '00005',
          projectName: 'XXX采购项目5',
          companyName: '税务所',
          companyPencent: '432.10',
          plan: [
            { planPhase: '意向阶段', planTime: '04', planStatus: '01' },
            { planPhase: '需求阶段', planTime: '06', planStatus: '01' },
            { planPhase: '计划阶段', planTime: '10', planStatus: '01' },
            { planPhase: '采购文件', planTime: '13', planStatus: '01' },
            { planPhase: '采购公告', planTime: '16', planStatus: '02' },
            { planPhase: '开评标', planTime: '18', planStatus: '03' },
            { planPhase: '评审结果', planTime: '21', planStatus: '03' },
            { planPhase: '结果公告', planTime: '23', planStatus: '03' },
            { planPhase: '签订合同', planTime: '25', planStatus: '03' }
          ]
        }
      ]
    }
  },
  methods: {}
}
</script>

<style scoped>
#bottomWrap {
  display: flex;
  position: absolute;
  left: 5px;
  top: 690px;
  width: 1690px;
  height: 365px;
  z-index: 7;
  opacity: 1;
  transform: rotate(0deg);
  margin-left: 110px;
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}
.tableLeft {
  max-height: 350px;
  background-color: #0b3047;
  font-size: 16px;
  color: #fff;
}
.tableLeft thead tr:nth-child(1) {
  height: 40px;
  background-color: #0f3055;
  text-align: center;
}
.tableLeft tr th:nth-child(1) {
  width: 70px;
}
.tableLeft tr th:nth-child(2) {
  width: 280px;
}
.tableLeft tr th:nth-child(3) {
  width: 100px;
}
.tableLeft tr th:nth-child(4) {
  width: 140px;
}
.tableLeft thead tr .progress {
  width: 120px;
}
.tableLeft tbody tr td {
  position: relative;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.tableLeft tbody tr td:nth-child(2) {
  text-align: left;
}
.tableLeft tbody tr td div {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
  width: 100%;
  height: 18px;
}
.tableLeft tbody tr td .activeProgress {
  background-color: #1ec7d7;
}
#echartBar4 {
  width: 1100px;
  height: 354px;
}
</style>
