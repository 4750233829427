<template>
  <!-- 中间开始 -->
  <div id="itemc2579760-3e0d-11ec-9df4-83999b721ef6">
    <div class="warpBox top">
      <div v-for="item in topData" :key="item.desc">
        <h3 class="itemTitle">{{ item.desc }}</h3>
        <p class="itemValue">{{ item.value }}</p>
        <p class="itemCompare">
          同比
          <span :class="item.state ? 'toUp' : 'toDown'"></span>
          <span class="itemCompareValue">{{ item.stateNum }}</span>
        </p>
      </div>
    </div>
    <div class="warpBox bottom">
      <div v-for="item in bottomData" :key="item.desc">
        <h3 class="itemTitle">{{ item.desc }}</h3>
        <p class="itemValue">{{ item.value }}</p>
        <p class="itemCompare">
          同比
          <span :class="item.state ? 'toUp' : 'toDown'"></span>
          <span class="itemCompareValue">{{ item.stateNum }}</span>
        </p>
      </div>
    </div>
  </div>
  <!-- 中间结束 -->
</template>

<script>
export default {
  data () {
    return {
      // 模拟后端接收数据
      showData: [
        { desc: '项目总数(项)', value: '109', state: 1, stateNum: '7.16%' },
        { desc: '已完成项目(项)', value: '96', state: 1, stateNum: '16.58%' },
        { desc: '执行中项目(项)', value: '13', state: 0, stateNum: '27.33%' },
        { desc: '项目完成率', value: '88.07%', state: 0, stateNum: '1.58%' },
        { desc: '项目总数(项)', value: '109', state: 1, stateNum: '7.16%' },
        { desc: '已完成项目(项)', value: '96', state: 1, stateNum: '16.58%' },
        { desc: '执行中项目(项)', value: '13', state: 0, stateNum: '27.33%' },
        { desc: '项目完成率', value: '88.07%', state: 0, stateNum: '1.58%' }
      ],
      topData: [], // 上部分数据
      bottomData: [] // 下部分数据
    }
  },
  mounted () {
    this.handleResData()
  },
  methods: {
    handleResData () {
      this.topData = this.showData.slice(0, 4)
      this.bottomData = this.showData.slice(4)
      console.log(this.topData, this.bottomData)
    }
  }
}
</script>

<style scoped>
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 {
  top: 90px;
  height: 600px;
}
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 > div {
  display: flex;
  justify-content: space-around;
  width: 720px;
  height: 200px;
  background: linear-gradient(to right, #122f57, #123b68);
  margin: 0 auto;
  margin-top: 50px;
  padding: 40px 0 0 30px;
  border-radius: 5px;
}
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 > div > div{
  flex-grow: 1;
}
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 .warpBox .itemTitle{
  font-size: 16px;
  color: #ccc;
}
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 .warpBox .itemValue{
  font-size: 40px;
  font-weight: bold;
  line-height: 120px;
  color: #1EC7D7;
}
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 .warpBox .itemCompare{
  font-size: 16px;
  color: #ccc;
}
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 .warpBox .toUp{
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 4px;
  border: 6px solid;
  border-color: transparent transparent #E81B1D;
}
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 .warpBox .toDown{
  display: inline-block;
  width: 0;
  height: 0;
  margin: 0 4px;
  border: 6px solid;
  border-color: #47CB39 transparent transparent;
}
#itemc2579760-3e0d-11ec-9df4-83999b721ef6 .warpBox .itemCompareValue{
  color: #fff;
  font-size: 18px;
  font-weight: bold;
}
</style>
