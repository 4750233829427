<template>
  <div>
    <!-- 右下标题开始-->
    <div id="itemd0cac130-3e05-11ec-a2e6-953464dcce14" class="centertitle">
      <p id="rightBottomP"><img src="../../../assets/images/trend.png" />合同执行趋势</p>
      <div class="boderBottomLine"></div>
    </div>
    <!-- 右下标题结束-->

    <!-- 右下echarts开始-->
    <div id="item93b724c0-3e03-11ec-a2e6-953464dcce14"></div>
    <div id="rightBottom1" style="display: none"></div>
    <!-- 右下echarts结束-->

    <!-- 右下背景图结束-->
  </div>
</template>

<script>
import { EventBus } from '../../../assets/js/eventBus.js'
import * as echarts from 'echarts'
export default {
  mounted() {
    this.setEcharts()
    this.rightBottom1Echarts()
    EventBus.$on('rightToChild', msg => {
      // A发送来的消息
      console.log('event:' + msg)
      this.rightFlag = msg
      if (this.rightFlag === 2) {
        document.querySelector('.boderBottomLine').style.width = '380%'
        document.getElementById('rightBottomP').lastChild.textContent = '合同执行趋势'
        document.getElementById('item93b724c0-3e03-11ec-a2e6-953464dcce14').style.display = 'block'
        document.getElementById('rightBottom1').style.display = 'none'
      } else {
        document.querySelector('.boderBottomLine').style.width = '300%'
        document.getElementById('rightBottomP').lastChild.textContent = '合同类型趋势分析'
        document.getElementById('item93b724c0-3e03-11ec-a2e6-953464dcce14').style.display = 'none'
        document.getElementById('rightBottom1').style.display = 'block'
      }
    })
  },
  data() {
    return {
      rightFlag: '',
      echartsData1: [
        {
          name: '总价合同',
          type: 'bar',
          data: [2.0, 4.9, 7.0, 23.2, 25.6]
        },
        {
          name: '入围合同',
          type: 'bar',
          data: [2.6, 5.9, 9.0, 26.4, 28.7]
        },
        {
          name: '单价合同',
          type: 'bar',
          data: [2.6, 5.9, 9.0, 26.4, 28.7]
        }
      ],
      echartsData2: [
        [90, 105, 84, 125, 110, 92, 98],
        [98, 125, 74, 115, 120, 92, 98]
      ]
    }
  },
  methods: {
    setEcharts() {
      const myChart = echarts.init(document.getElementById('item93b724c0-3e03-11ec-a2e6-953464dcce14'))
      var data = this.echartsData2
      var option
      option = {
        legend: {
          left: '50',
          top: '0',
          icon: 'stack',
          itemWidth: 16,
          itemHeight: 16,
          itemGap: 50,
          textStyle: {
            color: '#bfb7b7',
            fontSize: 18
          },
          data: [
            {
              name: '付款计划'
            },
            {
              name: '已付款'
            }
          ]
        },
        grid: {
          top: '16%',
          left: '2%',
          right: '8%',
          bottom: '10%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#bfb7b7'
              }
            },
            axisLabel: {
              textStyle: {
                color: '#bfb7b7',
                margin: 15
              }
            },
            data: ['2021', '2022', '2023', '2024']
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: 140,
            splitNumber: 7,
            splitLine: {
              show: true,
              lineStyle: {
                color: '#233e64'
              }
            },
            axisLine: { show: false },
            axisLabel: {
              margin: 20,
              textStyle: {
                color: '#bfb7b7'
              }
            },
            axisTick: { show: false }
          }
        ],
        series: [
          {
            name: '已付款',
            type: 'line',
            smooth: true,
            // 			symbol:'circle',  // 默认是空心圆（中间是白色的），改成实心圆
            symbolSize: 0,

            lineStyle: {
              normal: {
                color: '#3deaff' // 线条颜色
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    { offset: 0, color: 'rgba(61,234,255, 0.9)' },
                    { offset: 0.7, color: 'rgba(61,234,255, 0)' }
                  ],
                  false
                ),

                shadowColor: 'rgba(53,142,215, 0.9)',
                shadowBlur: 20
              }
            },
            data: data[0]
          },
          {
            name: '付款计划',
            type: 'line',
            smooth: true,
            // 			symbol:'circle',  // 默认是空心圆（中间是白色的），改成实心圆
            symbolSize: 0,

            lineStyle: {
              normal: {
                color: '#3deaff' // 线条颜色
              }
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(0,154,120,1)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,0,0, 0)'
                    }
                  ],
                  false
                ),
                shadowColor: 'rgba(53,142,215, 0.9)',
                shadowBlur: 20
              }
            },
            data: data[1]
          }
        ]
      }
      myChart.setOption(option)
    },
    rightBottom1Echarts() {
      var chartDom = document.getElementById('rightBottom1')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        legend: {
          x: 'center',
          y: '10',
          icon: 'stack',
          itemWidth: 20,
          itemGap: 30,
          itemHeight: 20,
          data: ['总价合同', '入围合同', '单价合同'],
          textStyle: {
            color: '#bfb7b7',
            fontSize: 16
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['2021', '2022', '2023', '2024'],
            axisLabel: {
              color: '#bfb7b7',
              fontSize: 16
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: '#233e64'
              }
            },
            axisLabel: {
              color: '#bfb7b7',
              fontSize: 16
            }
          }
        ],
        series: this.echartsData1
      }
      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
img {
  vertical-align: -10px;
}

#rightBottom1 {
  position: absolute;
  left: 32px;
  top: 650px;
  width: 450px;
  height: 320px;
  z-index: 4;
  display: block;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}

#itemd0cac130-3e05-11ec-a2e6-953464dcce14 {
  position: absolute;
  margin-left: -150px;
  top: 580px;
}

#item93b724c0-3e03-11ec-a2e6-953464dcce14,
#rightBottom1 {
  top: 630px;
  width: 450px;
  height: 320px;
}

p {
  font-size: 16px;
  color: #fff;
}

.boderBottomLine {
  width: 350%;
  height: 4px;
  background: rgba(69, 148, 173, 1);
  background: linear-gradient(to right, rgba(69, 148, 173, 1), rgba(69, 148, 173, 0));
  margin-top: 10px;
}
</style>
