<template>
  <!-- 外框、设置背景图 -->
  <div class="container">
    <!-- 头部通栏 -->
    <div id="item88b51490-3e05-11ec-a2e6-953464dcce14">
      <!-- 头部标题 -->
      <!-- 组件4ec4a890-3dec-11ec-a2e6-953464dcce14开始-->
      <div id="item4ec4a890-3dec-11ec-a2e6-953464dcce14" class="centertitle">
        <h1>鞍山数字资产运营有限公司管理平台</h1>
      </div>
      <!-- 组件4ec4a890-3dec-11ec-a2e6-953464dcce14结束-->

      <!-- 头部通栏背景图 -->
      <!-- 组件139cfda0-3dea-11ec-a2e6-953464dcce14开始-->
      <div id="item139cfda0-3dea-11ec-a2e6-953464dcce14">
        <img src="../../assets/images/top.png" alt="" :style="{ width: '100%', height: '50%', marginTop: '40px' }" />
      </div>
      <!-- 组件139cfda0-3dea-11ec-a2e6-953464dcce14结束-->
    </div>
    <!-- 左边三个控件 -->
    <div id="item8389fda0-3e05-11ec-a2e6-953464dcce14">
      <!-- 左上组件 -->
      <left-top />

      <!-- 左中组件 -->
      <left-center ref="leftCenterComponent2" />

      <!-- 左下组件 -->
      <left-bottom ref="leftBottomComponent2" />
    </div>

    <!-- 中间 -->
    <div id="itemc2579760-3e0d-11ec-9df4-83999b721ef6">
      <!-- 中间组件 -->
      <center-content ref="cnetentComponent2" />
    </div>

    <!-- 右边三个控件 -->
    <div id="item7a111060-3e05-11ec-a2e6-953464dcce14">
      <!-- 右上组件 -->
      <right-top ref="rightTopComponent2" />

      <!-- 右中组件 -->
      <right-center />

      <!-- 右下组件 -->
      <right-bottom />
    </div>
  </div>
</template>

<script>
import '../../assets/css/reset.css'
import '../../assets/css/custom-animation.css'
import '../../assets/css/index.css'
// 引入组件
import leftTop from './component/LeftTop'
import leftCenter from './component/LeftCenter'
import leftBottom from './component/LeftBottom'
import rightTop from './component/RightTop'
import rightCenter from './component/RightCenter'
import rightBottom from './component/RightBottom'
import centerContent from './component/CenterContent'
export default {
  data() {
    return {
      // 定时器
      timer: null
    }
  },
  components: {
    leftTop,
    leftCenter,
    leftBottom,
    rightTop,
    rightCenter,
    rightBottom,
    centerContent
  },
  mounted() {
    // 组件实现出场动画
    this.$nextTick(() => {
      //  头部
      playAnimation('#item88b51490-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInDown',
          name: '向下移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      //  左
      playAnimation('#item8389fda0-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInLeft',
          name: '向右移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      // 中间
      playAnimation('#itemc2579760-3e0d-11ec-9df4-83999b721ef6', [
        {
          type: 'bounceInDown',
          name: '向下弹入',
          delayed: 0.5,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      // 右
      playAnimation('#item7a111060-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInRight',
          name: '向左移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      /**
       *
       * @param {*} id
       * @param {*} animations
       * 声明初始值
       * 判断动画是否结束
       */
      function playAnimation(id, animations) {
        let index = 0
        const ele = document.querySelector(id)
        setAnimation(ele, animations[index])
        ele.addEventListener('webkitAnimationEnd', function () {
          if (index < animations.length - 1) {
            index++
            setAnimation(ele, animations[index])
          }
        })
      }

      /**
       *
       * @param {*} element
       * @param {*} animation
       * 取index对应的动画
       * 给元素animation重新赋值
       */
      function setAnimation(element, animation) {
        const action = `${animation.duration * 1000}ms ease ${animation.delayed * 1000}ms ${animation.loop ? 'infinite' : animation.frequency
          } normal both running ${animation.type}`
        element.style.animation = action
      }
    })
    // 自适应宽度处理
    document.getElementsByClassName('container')[0].style.zoom = document.body.clientWidth / 1920
    // 窗口发生改变自动刷新页面
    function fandou(time) {
      let timeout = null
      return function () {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          location.reload()
        }, time)
      }
    }
    window.onresize = fandou(1000)
    // 启动定时器
    this.timer = this.setPageAllAnimation()
  },
  beforeDestroy() {
    // 销毁定时器
    clearInterval(this.timer)
  },
  methods: {
    // 设置定时切换
    setPageAllAnimation() {
      let cnetentComponentN = 1
      let leftCenterCompontN = 1
      let leftBottomCompontN = 1
      let rightTopCompontN = 1
      return setInterval(() => {
        // 左中部分数据轮播
        if (leftCenterCompontN === 4) leftCenterCompontN = 2
        this.$refs.leftCenterComponent2.changeBanner(leftCenterCompontN, this.bufferMove)
        // 左下部分数据轮播
        if (leftBottomCompontN === 4) leftBottomCompontN = 2
        this.$refs.leftBottomComponent2.changeBanner(leftBottomCompontN, this.bufferMove)
        // 右上部分数据轮播
        if (rightTopCompontN === -2) rightTopCompontN = 0
        this.$refs.rightTopComponent2.changeBanner(rightTopCompontN, this.bufferMove)
        // 中间部分数据轮播
        if (cnetentComponentN > 3) cnetentComponentN = 0
        this.$refs.cnetentComponent2.changeChooseCards(cnetentComponentN)
        cnetentComponentN++
        leftCenterCompontN++
        leftBottomCompontN++
        rightTopCompontN--
      }, 6000)
    },
    /*
    减速运动
    @param eleObj:元素对象
    @param styleObj:样式对象集合  属性：目标值
    @param interval:时间间隔
    @param callBack:回调函数
    */
    bufferMove(eleObj, styleObj, interval, callBack) {
      window.clearInterval(eleObj.timer)
      eleObj.timer = window.setInterval(function () {
        // 默认值是true
        var flag = true
        for (var styleAttr in styleObj) {
          const getStyle = eleObj.currentStyle
            ? eleObj.currentStyle[styleAttr]
            : window.getComputedStyle(eleObj)[styleAttr]
          var cur = styleAttr === 'opacity' ? parseFloat(getStyle * 100) : parseFloat(getStyle)
          var speed = (styleObj[styleAttr] - cur) / 10
          speed = speed > 0 ? Math.ceil(speed) : Math.floor(speed)

          if (cur !== styleObj[styleAttr]) {
            flag = false
          }

          if (styleAttr === 'opacity') {
            eleObj.style.opacity = (cur + speed) / 100
            eleObj.style.filter = 'alpha(opacity=' + (cur + speed) + ')'
          } else {
            eleObj.style[styleAttr] = cur + speed + 'px'
          }
        }

        if (flag) {
          window.clearInterval(eleObj.timer)
          callBack && callBack.call(eleObj)
        }
      }, interval)
    }
  }
}
</script>

<style scoped>
.container {
  z-index: 9;
  background: rgba(5, 11, 22, 1) url(../../assets/images/bg.jpg) no-repeat;
  background-size: 100% 100%;
}

.centertitle h1 {
  margin-top: 10px;
  font-size: 26px;
}

/* 左边外框 */
#item8389fda0-3e05-11ec-a2e6-953464dcce14 {
  height: 980px;
}

/* 右边外框 */
#item7a111060-3e05-11ec-a2e6-953464dcce14 {
  height: 980px;
}

#item4ec4a890-3dec-11ec-a2e6-953464dcce14 {
  top: 6px;
}

#itemc2579760-3e0d-11ec-9df4-83999b721ef6 {
  top: 90px;
  height: 940px;
}
</style>
