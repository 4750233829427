<template>
  <div id="content">
    <div class="topContentBox">
      <div v-for="item in showTopData" :key="item.desc" class="contentItemBox">
        <p>{{ item.desc }}</p>
        <p>
          <span>{{ item.value }}</span>{{ item.value.substr(-1) === '%' ? '' : '万元' }}
        </p>
      </div>
    </div>
    <!-- 中间内容盒 -->
    <div class="centerContentBox">
      <div class="centerTop">
        <h3>{{ showBottomTitle }}</h3>
        <p>
          <span>{{ totleProjectNum }}</span>
          {{ showTotleUnit }}
        </p>
      </div>
      <!-- 中间左上块的内容 -->
      <div class="showProjectNumBox lastBeforeProjectNumBox">
        <div id="lastEchart" class="ringPicBoxLeft"></div>
        <div class="descBoxLeft">
          <p class="proNum">{{ cneterData.lastYearProNum }}{{ showTotleUnit === '%' ? '%' : '' }}</p>
          <h3 class="proNumName">{{ resBottomData[2].years }}年{{ showBottomTitle }}</h3>
        </div>
      </div>

      <!-- 中间左下块的内容 -->
      <div class="showProjectNumBox lastProjectNumBox">
        <div id="lastBeforeEchart" class="ringPicBoxLeft"></div>
        <div class="descBoxLeft">
          <p class="proNum">{{ cneterData.lastBeforeYearProNum }}{{ showTotleUnit === '%' ? '%' : '' }}</p>
          <h3 class="proNumName">{{ resBottomData[3].years }}年{{ showBottomTitle }}</h3>
        </div>
      </div>

      <!-- 中间右下上块的内容 -->
      <div class="showProjectNumBox beforeProjectNumBox">
        <div id="beforeEchart" class="ringPicBoxRight"></div>
        <div class="descBoxRight">
          <p class="proNum">{{ cneterData.beforeYearProNum }}{{ showTotleUnit === '%' ? '%' : '' }}</p>
          <h3 class="proNumName">{{ resBottomData[1].years }}年{{ showBottomTitle }}</h3>
        </div>
      </div>

      <!-- 中间右下块的内容 -->
      <div class="showProjectNumBox nowProjectNumBox">
        <div id="nowEchart" class="ringPicBoxRight"></div>
        <div class="descBoxRight">
          <p class="proNum">{{ cneterData.nowYearProNum }}{{ showTotleUnit === '%' ? '%' : '' }}</p>
          <h3 class="proNumName">{{ resBottomData[0].years }}年{{ showBottomTitle }}</h3>
        </div>
      </div>
    </div>

    <!-- 底部选项卡 -->
    <div class="bottomContentBox">
      <div class="side">
        <p :class="chooseCard[0] ? 'activeLeft' : 'unactiveLeft'">项目统计</p>
      </div>
      <div class="center">
        <p :class="chooseCard[1] ? 'activeCenter' : 'unactiveCenter'">招商统计</p>
      </div>
      <div class="center">
        <p :class="chooseCard[2] ? 'activeCenter' : 'unactiveCenter'">招采统计</p>
      </div>
      <div class="side">
        <p :class="chooseCard[3] ? 'activeRight' : 'unactiveRight'">生产总值统计</p>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  mounted() {
    // 处理上方展示数据
    this.dealWithResData()
    // 处理下方数据
    this.changeChooseCards(0)
  },
  data() {
    return {
      chooseCard: [1, 0, 0, 0], // 底部四个选项卡哪一个是选中状态
      // 模拟数据 - 上方三个看板的数据
      resTopData: [
        {
          currentYearTotalPlanAmount: 145335.75, // 本年度项目总预算
          currentYearTotalLastPrice: 111251.08, // 本年度项目总中标金额
          currentYearCapitalSavingRate: 0.2345 // 本年度项目生产总值增长率
        }
      ],
      showTopData: [], // 用来展示的数据

      // 模拟数据 - 下方图上的数据
      resBottomData: [
        {
          totalPlanAmount: 145335.75, // 年份对应招商金额
          capitalSavingRate: 0.2345, // 年份对应生产总值增长率
          years: '2024', // 年份
          projectNums: 102, // 年份对应项目数量
          totalLastPrice: 111251.08 // 年份对应项目中标金额
        },
        {
          totalPlanAmount: 139822.67,
          capitalSavingRate: 0.0812,
          years: '2023',
          projectNums: 129,
          totalLastPrice: 128462.35
        },
        {
          totalPlanAmount: 222108.69,
          capitalSavingRate: 0.1699,
          years: '2022',
          projectNums: 126,
          totalLastPrice: 184376.48
        },
        {
          totalPlanAmount: 99909.4,
          capitalSavingRate: 0.4277,
          years: '2021',
          projectNums: 100,
          totalLastPrice: 57178.74
        }
      ],
      // 下方展示的数据标题
      showBottomTitle: '项目数量',
      // 展示的总数
      totleProjectNum: 0,
      // 展示总数的单位
      showTotleUnit: '个',
      // 用于展示的下方数据集
      cneterData: {
        lastBeforeYearProNum: 100,
        lastYearProNum: 110,
        beforeYearProNum: 120,
        nowYearProNum: 150
      }
    }
  },
  methods: {
    /*
        创建echarts图
        id: 画图的容器id
        rateNum: 环的百分比
    */
    setEcharts(id, rateNum) {
      var chartDom = document.getElementById(id)
      var myChart = echarts.init(chartDom)
      var option
      option = {
        series: [
          {
            type: 'gauge',
            // 开始的旋转角度
            startAngle: 90,
            endAngle: -270,
            // 线性渐变，前四个参数分别是 x0, y0, x2, y2, 范围从 0 - 1，相当于在图形包围盒中的百分比，如果 globalCoord 为 `true`，则该四个值是绝对的像素位置
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: '#BABA24' // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: '#11FCFA' // 100% 处的颜色
                }
              ],
              global: false // 缺省为 false
            },
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              // 两头是否是圆弧
              roundCap: false,
              clip: false,
              itemStyle: {
                borderWidth: 0
              }
            },
            axisLine: {
              lineStyle: {
                // 环的宽度
                width: 6,
                // 没有覆盖的背景颜色
                color: [[1, 'transparent']]
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            data: [
              {
                value: rateNum,
                name: 'Perfect',
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '10%']
                }
              }
            ],
            title: {
              show: false,
              fontSize: 14
            },
            detail: {
              width: 50,
              height: 14,
              fontSize: 14,
              fontWeight: 900,
              color: '#00FBFC',
              borderColor: 'auto',
              borderRadius: 20,
              borderWidth: 0,
              formatter: `${rateNum}%`
            }
          }
        ]
      }

      option && myChart.setOption(option)
    },

    // 处理上方三个看板的接收数据
    dealWithResData() {
      //
      this.showTopData = [
        {
          desc: '本年项目总预算',
          value: String(this.resTopData[0].currentYearTotalPlanAmount)
        },
        {
          desc: '本年签约总金额',
          value: String(this.resTopData[0].currentYearTotalLastPrice)
        },
        {
          desc: '本年生产总值增长率',
          value: Number(this.resTopData[0].currentYearCapitalSavingRate).toFixed(2) + '%'
        }
      ]
    },

    // 切换选项卡时数据重新赋值与重新绘制echarts图
    changeChooseCards(n) {
      this.chooseCard = this.chooseCard.map((item, index) => (n === index ? 1 : 0))
      // 按项目数量统计
      if (n === 0) {
        this.cneterData = {
          lastBeforeYearProNum: this.resBottomData[3].projectNums,
          lastYearProNum: this.resBottomData[2].projectNums,
          beforeYearProNum: this.resBottomData[1].projectNums,
          nowYearProNum: this.resBottomData[0].projectNums
        }
        // 重新计算总数
        this.totleProjectNum =
          this.resBottomData[3].projectNums +
          this.resBottomData[2].projectNums +
          this.resBottomData[1].projectNums +
          this.resBottomData[0].projectNums
        this.showBottomTitle = '项目数量'
        this.showTotleUnit = '个'
      } else if (n === 1) {
        // 按招商金额统计
        this.cneterData = {
          lastBeforeYearProNum: this.resBottomData[3].totalPlanAmount,
          lastYearProNum: this.resBottomData[2].totalPlanAmount,
          beforeYearProNum: this.resBottomData[1].totalPlanAmount,
          nowYearProNum: this.resBottomData[0].totalPlanAmount
        }
        // 重新计算总数
        this.totleProjectNum = Number(
          this.resBottomData[3].totalPlanAmount +
          this.resBottomData[2].totalPlanAmount +
          this.resBottomData[1].totalPlanAmount +
          this.resBottomData[0].totalPlanAmount
        ).toFixed(2)
        this.showBottomTitle = '招商金额'
        this.showTotleUnit = '万元'
      } else if (n === 2) {
        // 按中标金额统计
        this.cneterData = {
          lastBeforeYearProNum: this.resBottomData[3].totalLastPrice,
          lastYearProNum: this.resBottomData[2].totalLastPrice,
          beforeYearProNum: this.resBottomData[1].totalLastPrice,
          nowYearProNum: this.resBottomData[0].totalLastPrice
        }
        // 重新计算总数
        this.totleProjectNum = Number(
          this.resBottomData[3].totalLastPrice +
          this.resBottomData[2].totalLastPrice +
          this.resBottomData[1].totalLastPrice +
          this.resBottomData[0].totalLastPrice
        ).toFixed(2)
        this.showBottomTitle = '中标金额'
        this.showTotleUnit = '万元'
      } else {
        this.cneterData = {
          lastBeforeYearProNum: this.resBottomData[3].capitalSavingRate * 100,
          lastYearProNum: this.resBottomData[2].capitalSavingRate * 100,
          beforeYearProNum: this.resBottomData[1].capitalSavingRate * 100,
          nowYearProNum: this.resBottomData[0].capitalSavingRate * 100
        }
        // 重新计算总数
        this.totleProjectNum = (
          Number(
            this.resBottomData[3].capitalSavingRate +
            this.resBottomData[2].capitalSavingRate +
            this.resBottomData[1].capitalSavingRate +
            this.resBottomData[0].capitalSavingRate
          ) * 100
        ).toFixed(2)
        this.showBottomTitle = '生产总值增长率'
        this.showTotleUnit = '%'
      }

      // 重新绘制echarts图
      this.setEcharts('lastEchart', Math.round((this.cneterData.lastYearProNum / this.totleProjectNum) * 100))
      this.setEcharts(
        'lastBeforeEchart',
        Math.round((this.cneterData.lastBeforeYearProNum / this.totleProjectNum) * 100)
      )
      this.setEcharts('beforeEchart', Math.round((this.cneterData.beforeYearProNum / this.totleProjectNum) * 100))
      this.setEcharts('nowEchart', Math.round((this.cneterData.nowYearProNum / this.totleProjectNum) * 100))
    }
  }
}
</script>

<style scoped>
#content {
  width: 100%;
  height: 100%;
  background: url(../../../assets/images/中间1.png) no-repeat center center;
}

.topContentBox {
  height: 99px;
  margin: 40px 10px 0;
  display: flex;
}

.contentItemBox {
  flex-grow: 1;
  margin: 0 10px;
  box-shadow: 0 0 20px #00e0e9 inset;
  color: #fff;
  /* border: 2px solid #00E0E9; */
  background: linear-gradient(to left, #00e0e9, #00e0e9) left top no-repeat,
    linear-gradient(to left, #00e0e9, #00e0e9) left top no-repeat,
    linear-gradient(to left, #00e0e9, #00e0e9) right top no-repeat,
    linear-gradient(to left, #00e0e9, #00e0e9) right top no-repeat,
    linear-gradient(to left, #00e0e9, #00e0e9) left bottom no-repeat,
    linear-gradient(to left, #00e0e9, #00e0e9) left bottom no-repeat,
    linear-gradient(to left, #00e0e9, #00e0e9) right bottom no-repeat,
    linear-gradient(to left, #00e0e9, #00e0e9) right bottom no-repeat;
  background-size: 2px 20px, 20px 2px;
}

.contentItemBox p {
  text-align: center;
}

.contentItemBox p:nth-child(1) {
  margin-top: 10px;
  font-size: 16px;
  line-height: 30px;
}

.contentItemBox p:nth-child(2) {
  margin-bottom: 10px;
}

.contentItemBox p:nth-child(2) span {
  font-size: 35px;
  font-weight: bold;
  color: #006ede;
}

.contentItemBox:nth-child(2) p:nth-child(2) span {
  color: #00d2ee;
}

.contentItemBox:nth-child(3) p:nth-child(2) span {
  color: #fcdc7a;
}

/* 中间样式 */
.centerContentBox {
  position: relative;
  width: 100%;
  height: 680px;
}

.centerContentBox .centerTop {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 280px;
  height: 100px;
  text-align: center;
}

.centerContentBox .centerTop h3 {
  margin-top: 16px;
  color: #fff;
  font-size: 20px;
  line-height: 30px;
}

.centerContentBox .centerTop p {
  color: #00b3fd;
  font-size: 20px;
  font-weight: bold;
}

.centerContentBox .centerTop p span {
  font-size: 40px;
  text-shadow: 0 0 26px #ffffff;
}

.showProjectNumBox {
  position: absolute;
  width: 220px;
  height: 74px;
}

.showProjectNumBox .ringPicBoxLeft {
  position: absolute;
  left: 7px;
  top: 1px;
  width: 70px;
  height: 70px;
}

.showProjectNumBox .ringPicBoxRight {
  position: absolute;
  right: 7px;
  top: 1px;
  width: 70px;
  height: 70px;
}

.showProjectNumBox .descBoxLeft {
  position: absolute;
  left: 90px;
}

.showProjectNumBox .descBoxRight {
  position: absolute;
  right: 90px;
  text-align: right;
}

.showProjectNumBox .proNum {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -2px;
  color: #007ffc;
}

.showProjectNumBox .proNumName {
  font-size: 14px;
  font-weight: normal;
  color: #fff;
}

.lastBeforeProjectNumBox {
  top: 200px;
  left: 12px;
}

.lastProjectNumBox {
  top: 315px;
  left: 41px;
}

.beforeProjectNumBox {
  top: 199px;
  right: 5px;
}

.nowProjectNumBox {
  top: 315px;
  right: 35px;
}

/* 底部样式 */
.bottomContentBox {
  display: flex;
  width: 771px;
  height: 71px;
  background: url(../../../assets/images/背景线框.png) center center no-repeat;
  margin: 0 auto;
  margin-top: 36px;
}

.bottomContentBox .center {
  flex-grow: 1;
}

.bottomContentBox .side {
  flex-grow: 1.2;
}

.bottomContentBox p {
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  color: #00e3fe;
  line-height: 70px;
}

.bottomContentBox .unactiveLeft {
  background: url(../../../assets/images/左蓝.png) no-repeat center center;
  background-size: 85% 70%;
}

.bottomContentBox .activeLeft {
  background: url(../../../assets/images/左黄.png) no-repeat center center;
  background-size: 85% 70%;
}

.bottomContentBox .unactiveCenter {
  background: url(../../../assets/images/中蓝.png) no-repeat center center;
}

.bottomContentBox .activeCenter {
  background: url(../../../assets/images/中黄.png) no-repeat center center;
}

.bottomContentBox .unactiveRight {
  background: url(../../../assets/images/右蓝.png) no-repeat center center;
  background-size: 85% 70%;
}

.bottomContentBox .activeRight {
  background: url(../../../assets/images/右黄.png) no-repeat center center;
  background-size: 85% 70%;
}
</style>
