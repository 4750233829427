import { render, staticRenderFns } from "./RightCenter.vue?vue&type=template&id=f1f7c764&scoped=true"
import script from "./RightCenter.vue?vue&type=script&lang=js"
export * from "./RightCenter.vue?vue&type=script&lang=js"
import style0 from "./RightCenter.vue?vue&type=style&index=0&id=f1f7c764&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1f7c764",
  null
  
)

export default component.exports