<template>
  <div>
    <!-- 右上标题开始-->
    <div
      id="item9c61daa0-3e05-11ec-a2e6-953464dcce14"
      :style="{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        lineHeight: '1.5',
        color: '#ffffff',
        textAlign: 'center',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
      }"
      class="centertitle"
    >
      <h2>合同数据分析</h2>
    </div>
    <!-- 右上标题结束-->

    <!-- 右上echarts开始-->
    <div id="item62c8d410-3e05-11ec-a2e6-953464dcce14">
      <span @click="flag1" style="margin-left: 150px">合同签订</span>
      <span @click="flag2" style="margin-left: 120px">合同执行</span>
    </div>
    <!-- 右上echarts结束-->
    <!-- 右上背景图结束-->
  </div>
</template>

<script>
import { EventBus } from '../../../assets/js/eventBus.js'
export default {
  data () {
    return {
      rightFlag: 1
    }
  },
  methods: {
    flag1 () {
      this.rightFlag = 1
      const dom1 = document.querySelectorAll('#item62c8d410-3e05-11ec-a2e6-953464dcce14 span')
      dom1[1].style.fontSize = '20px'
      dom1[1].style.fontWeight = 'normal'
      dom1[1].style.borderBottom = '0px'
      dom1[0].style.borderBottom = '5px solid #2A6A7A'
      dom1[0].style.fontWeight = 'bold'
      dom1[0].style.fontSize = '24px'
      EventBus.$emit('rightToChild', this.rightFlag)
    },
    flag2 () {
      const dom1 = document.querySelectorAll('#item62c8d410-3e05-11ec-a2e6-953464dcce14 span')
      dom1[0].style.fontSize = '20px'
      dom1[0].style.fontWeight = 'normal'
      dom1[0].style.borderBottom = '0px'
      dom1[1].style.borderBottom = '5px solid #2A6A7A'
      dom1[1].style.fontWeight = 'bold'
      dom1[1].style.fontSize = '24px'
      this.rightFlag = 2
      EventBus.$emit('rightToChild', this.rightFlag)
    }
  }
}
</script>

<style scoped>
span {
  color: aliceblue;
  font-size: 20px;
  padding-bottom: 5px;
}
#item9c61daa0-3e05-11ec-a2e6-953464dcce14 {
  width: 199px;
  left: 100px;
  top: 30px;
}
#item62c8d410-3e05-11ec-a2e6-953464dcce14 {
  top: 110px;
  left: 0px;
  height: 100px;
}
#item62c8d410-3e05-11ec-a2e6-953464dcce14 span:last-child {
  font-size: 24px;
  font-weight: bold;
  border-bottom: 5px solid #2a6a7a;
}
h2 {
  color: #fff;
  text-shadow: 0 0 10px, 0 0 20px;
}
</style>
