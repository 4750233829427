<template>
  <!-- 外框、设置背景图 -->
  <div class="container">
    <!-- 头部标题 -->
    <!-- 组件头部标题开始-->
    <div id="item4ec4a890-3dec-11ec-a2e6-953464dcce14" class="centertitle">
      <h1>集采中心年底作战图({{ statisticalMonth.substr(-2) }}月)</h1>
    </div>
    <!-- 组件头部标题结束-->

    <!-- 头部通栏背景图 -->
    <!-- 组件头部背景图开始-->
    <div id="item139cfda0-3dea-11ec-a2e6-953464dcce14">
      <img src="../../assets/images/顶部栏.png" alt="" :style="{ width: '100%', height: '200%' }" />
    </div>
    <!-- 组件头部背景图结束-->

    <!-- 内容 -->
    <main-content />
  </div>
  <!-- 外框背景图结束 -->
</template>

<script>
import mainContent from './component/MainContent'
export default {
  components: {
    mainContent
  },
  data() {
    return {
      // 模拟数据
      statisticalMonth: '2022/12' // 统计年月
    }
  },
  mounted() {
    // 组件实现出场动画
    this.$nextTick(() => {
      //  头部
      //   playAnimation("#item88b51490-3e05-11ec-a2e6-953464dcce14", [
      //     {
      //       type: "fadeInDown",
      //       name: "向下移入",
      //       delayed: 0,
      //       loop: false,
      //       frequency: 1,
      //       duration: 1,
      //       isPlayer: false,
      //       isDisabled: false,
      //     },
      //   ]);
      //  左
      //   playAnimation("#item8389fda0-3e05-11ec-a2e6-953464dcce14", [
      //     {
      //       type: "fadeInLeft",
      //       name: "向右移入",
      //       delayed: 0,
      //       loop: false,
      //       frequency: 1,
      //       duration: 1,
      //       isPlayer: false,
      //       isDisabled: false,
      //     },
      //   ]);
      // 中间
      playAnimation('#content5', [
        {
          type: 'bounceInDown',
          name: '向下弹入',
          delayed: 0.5,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      // 右
      //   playAnimation("#item7a111060-3e05-11ec-a2e6-953464dcce14", [
      //     {
      //       type: "fadeInRight",
      //       name: "向左移入",
      //       delayed: 0,
      //       loop: false,
      //       frequency: 1,
      //       duration: 1,
      //       isPlayer: false,
      //       isDisabled: false,
      //     },
      //   ]);

      /**
       *
       * @param {*} id
       * @param {*} animations
       * 声明初始值
       * 判断动画是否结束
       */
      function playAnimation(id, animations) {
        let index = 0
        const ele = document.querySelector(id)
        setAnimation(ele, animations[index])
        ele.addEventListener('webkitAnimationEnd', function () {
          if (index < animations.length - 1) {
            index++
            setAnimation(ele, animations[index])
          }
        })
      }

      /**
       *
       * @param {*} element
       * @param {*} animation
       * 取index对应的动画
       * 给元素animation重新赋值
       */
      function setAnimation(element, animation) {
        const action = `${animation.duration * 1000}ms ease ${animation.delayed * 1000}ms ${animation.loop ? 'infinite' : animation.frequency
          } normal both running ${animation.type}`
        element.style.animation = action
      }
    })
    // 自适应宽度处理
    document.getElementsByClassName('container')[0].style.zoom = document.body.clientWidth / 1920
    // 窗口发生改变自动刷新页面
    function fandou(time) {
      let timeout = null
      return function () {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          location.reload()
        }, time)
      }
    }
    window.onresize = fandou(1000)
  }
}
</script>

<style scoped>
.container {
  background: url(../../assets/images/作战图背景.png) no-repeat;
}

#item4ec4a890-3dec-11ec-a2e6-953464dcce14 {
  top: 6px;
}

#item4ec4a890-3dec-11ec-a2e6-953464dcce14 h1 {
  letter-spacing: 6px;
  font-weight: bold;
  color: #fff;
  font-size: 36px;
}

#item139cfda0-3dea-11ec-a2e6-953464dcce14 {
  overflow: hidden;
}
</style>
