<template>
  <div>
    <!-- 左上标题头 -->
    <!-- 组件05cd9ca0-3dec-11ec-a2e6-953464dcce14开始-->
    <div
      id="item05cd9ca0-3dec-11ec-a2e6-953464dcce14"
      :style="{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        lineHeight: '1.5',
        color: '#ffffff',
        textAlign: 'center',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
      }"
      class="centertitle"
    >
      <h2>业务数据分析</h2>
    </div>
    <div id="item8d374050-3e10-11ec-b331-d9ec61910d4a">
      <span @click="flag1">采购方式</span>
      <span @click="flag2">组织形式</span>
      <span @click="flag3">需求部门</span>
      <span @click="flag4">供应商</span>
    </div>
  </div>
</template>

<script>
import { EventBus } from '../../../assets/js/eventBus.js'
export default {
  data () {
    return {
      leftFlag: 4
    }
  },
  mounted () {},
  methods: {
    flag1 () {
      this.leftFlag = 1
      const dom1 = document.querySelectorAll('#item8d374050-3e10-11ec-b331-d9ec61910d4a span')
      for (let index = 0; index < dom1.length; index++) {
        dom1[index].style.fontSize = '20px'
        dom1[index].style.fontWeight = 'normal'
        dom1[index].style.borderBottom = '0px'
      }
      dom1[0].style.borderBottom = '5px solid #2A6A7A'
      dom1[0].style.fontWeight = 'bold'
      dom1[0].style.fontSize = '24px'
      EventBus.$emit('leftToChild', this.leftFlag)
    },
    flag2 () {
      this.leftFlag = 2
      const dom1 = document.querySelectorAll('#item8d374050-3e10-11ec-b331-d9ec61910d4a span')
      for (let index = 0; index < dom1.length; index++) {
        dom1[index].style.fontSize = '20px'
        dom1[index].style.fontWeight = 'normal'
        dom1[index].style.borderBottom = '0px'
      }
      dom1[1].style.borderBottom = '5px solid #2A6A7A'
      dom1[1].style.fontWeight = 'bold'
      dom1[1].style.fontSize = '24px'
      EventBus.$emit('leftToChild', this.leftFlag)
    },
    flag3 () {
      this.leftFlag = 3
      const dom1 = document.querySelectorAll('#item8d374050-3e10-11ec-b331-d9ec61910d4a span')
      for (let index = 0; index < dom1.length; index++) {
        dom1[index].style.fontSize = '20px'
        dom1[index].style.fontWeight = 'normal'
        dom1[index].style.borderBottom = '0px'
      }
      dom1[2].style.borderBottom = '5px solid #2A6A7A'
      dom1[2].style.fontWeight = 'bold'
      dom1[2].style.fontSize = '24px'
      EventBus.$emit('leftToChild', this.leftFlag)
    },
    flag4 () {
      this.leftFlag = 4
      const dom1 = document.querySelectorAll('#item8d374050-3e10-11ec-b331-d9ec61910d4a span')
      for (let index = 0; index < dom1.length; index++) {
        dom1[index].style.fontSize = '20px'
        dom1[index].style.fontWeight = 'normal'
        dom1[index].style.borderBottom = '0px'
      }
      dom1[3].style.borderBottom = '5px solid #2A6A7A'
      dom1[3].style.fontWeight = 'bold'
      dom1[3].style.fontSize = '24px'
      EventBus.$emit('leftToChild', this.leftFlag)
    }
  }
}
</script>

<style scoped>
span {
  color: aliceblue;
  font-size: 20px;
  margin-right: 30px;
  padding-bottom: 5px;
}
#item05cd9ca0-3dec-11ec-a2e6-953464dcce14 {
  width: 199px;
  left: 200px;
  top: 30px;
}
#item8d374050-3e10-11ec-b331-d9ec61910d4a {
  top: 110px;
  left: 100px;
  height: 100px;
}
#item8d374050-3e10-11ec-b331-d9ec61910d4a span:last-child {
  font-size: 24px;
  font-weight: bold;
  border-bottom: 5px solid #2a6a7a;
}
h2 {
  color: #fff;
  text-shadow: 0 0 10px, 0 0 20px;
}
</style>
