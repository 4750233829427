var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"centertitle",style:({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '18px',
      lineHeight: '1.5',
      color: '#ffffff',
      textAlign: 'center',
      textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
    }),attrs:{"id":"itemd0cac130-3e05-11ec-a2e6-953464dcce14"}},[_c('p',[_vm._v("供应商项目数量占比情况")])]),_c('div',{attrs:{"id":"item93b724c0-3e03-11ec-a2e6-953464dcce14"}},[_c('table',{staticClass:"projectNumTable",attrs:{"rules":"none"}},[_vm._m(0),_c('tbody',_vm._l((_vm.showData),function(item){return _c('tr',{key:item.companyName},[_c('td',[_vm._v(_vm._s(item.no))]),_c('td',[_vm._v(_vm._s(item.companyName))]),_c('td',[_vm._v(_vm._s(item.companyPencent))])])}),0)])]),_c('div',{attrs:{"id":"item71b4b580-3deb-11ec-a2e6-953464dcce14"}},[_c('img',{style:({ width: '100%', height: '100%' }),attrs:{"src":require("../../../assets/images/线框.png"),"alt":""}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("序号")]),_c('th',[_vm._v("企业名称")]),_c('th',[_vm._v("占比")])])])
}]

export { render, staticRenderFns }