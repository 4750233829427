<template>
  <div>
    <!-- 左上标题头 -->
    <!-- 组件05cd9ca0-3dec-11ec-a2e6-953464dcce14开始-->
    <div
      id="item05cd9ca0-3dec-11ec-a2e6-953464dcce14"
      :style="{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '18px',
        lineHeight: '1.5',
        color: '#ffffff',
        textAlign: 'center',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
      }"
      class="centertitle"
    >
      <p>项目采购方式占比情况</p>
    </div>
    <!-- 组件05cd9ca0-3dec-11ec-a2e6-953464dcce14结束-->
    <!-- 左上echart图展示区域 -->
    <!-- 组件8d374050-3e10-11ec-b331-d9ec61910d4a开始-->
    <div id="item8d374050-3e10-11ec-b331-d9ec61910d4a"></div>
    <!-- 组件8d374050-3e10-11ec-b331-d9ec61910d4a结束-->
    <!-- 左上容器背景图 -->
    <!-- 组件da42a880-eaf0-4a65-b71f-a313e2bd4daf开始-->
    <!-- 最大外框背景图 -->
    <div id="itemda42a880-eaf0-4a65-b71f-a313e2bd4daf">
      <img src="../../../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
    </div>
    <!-- 组件da42a880-eaf0-4a65-b71f-a313e2bd4daf结束-->
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  mounted () {
    this.setEcharts()
  },
  data () {
    return {
      // 模拟数据
      resData: [
        {
          purchaseWay: '竞争性磋商', // 采购方式名称
          purchaseWayPencent: 0.1357, // 采购方式对应项目数量占比
          projectNums: 62 // 采购方式对应项目数量
        },
        {
          purchaseWay: '公开招标',
          purchaseWayPencent: 0.5339,
          projectNums: 244
        },
        {
          purchaseWay: '单一来源',
          purchaseWayPencent: 0.1313,
          projectNums: 60
        },
        {
          purchaseWay: '协议供货',
          purchaseWayPencent: 0.0306,
          projectNums: 14
        },
        {
          purchaseWay: '批量采购',
          purchaseWayPencent: 0.0438,
          projectNums: 20
        },
        {
          purchaseWay: '电子卖场',
          purchaseWayPencent: 0.0044,
          projectNums: 2
        },
        {
          purchaseWay: '竞争性谈判',
          purchaseWayPencent: 0.0438,
          projectNums: 20
        },
        {
          purchaseWay: '定点采购',
          purchaseWayPencent: 0.0109,
          projectNums: 5
        },
        {
          purchaseWay: '审核入围',
          purchaseWayPencent: 0.0525,
          projectNums: 24
        },
        {
          purchaseWay: '邀请招标',
          purchaseWayPencent: 0.0066,
          projectNums: 3
        },
        {
          purchaseWay: '询价',
          purchaseWayPencent: 0.0044,
          projectNums: 2
        },
        {
          purchaseWay: '网上竞价',
          purchaseWayPencent: 0.0022,
          projectNums: 1
        }
      ]
    }
  },
  methods: {
    setEcharts () {
      const showPieData = []
      this.resData.forEach((item) => {
        showPieData.push({
          name: item.purchaseWay,
          value: (item.purchaseWayPencent * 100).toFixed(2),
          projectNums: item.projectNums
        })
      })
      var chartDom = document.getElementById('item8d374050-3e10-11ec-b331-d9ec61910d4a')
      var myChart = echarts.init(chartDom)
      var option
      option = {
        // tooltip: {
        //   trigger: "item",
        // },
        series: [
          {
            name: '',
            type: 'pie',
            radius: [50, 80],
            top: 0,
            itemStyle: {
              // borderColor: '#fff',
              // borderWidth: 1
            },
            // emphasis: {
            //   label: {
            //     show: true,
            //     fontSize: "40",
            //     fontWeight: "bold",
            //   },
            // },
            label: {
              alignTo: 'edge',
              formatter: '{b} : {c} %',
              minMargin: 5,
              edgeDistance: 0,
              // lineHeight: 0,
              fontSize: 16,
              color: '#fff',
              rich: {
                // vaule: {
                // fontSize: 20,
                // color: "#fff",
                // },
              }
            },
            labelLine: {
              length: 0,
              length2: -30,
              maxSurfaceAngle: 100
            },
            // labelLayout: function (params) {
            //   const isLeft = params.labelRect.x < myChart.getWidth() / 2;
            //   const points = params.labelLinePoints;
            //   // Update the end point.
            //   points[2][0] = isLeft
            //     ? params.labelRect.x
            //     : params.labelRect.x + params.labelRect.width;
            //   return {
            //     labelLinePoints: points
            //   };
            // },
            data: showPieData
          }
        ]
      }

      option && myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
/* 左上背景图位置及大小 */
#itemda42a880-eaf0-4a65-b71f-a313e2bd4daf {
  left: 20px;
  width: 530px;
  height: 310px;
}
/* 标题位置 */
#item05cd9ca0-3dec-11ec-a2e6-953464dcce14 {
  top: 14px;
}
/* echarts图的位置及大小 */
#item8d374050-3e10-11ec-b331-d9ec61910d4a {
  top: 22px;
  height: 270px;
}
</style>
