<template>
  <div>
    <!-- 左下标题头 -->
    <!-- 组件d86d3f90-3deb-11ec-a2e6-953464dcce14开始-->
    <div id="itemd86d3f90-3deb-11ec-a2e6-953464dcce14" class="centertitle">
      <p id="leftBottomP"><img src="../../../assets/images/rank.png" />成交金额排名</p>
      <div class="boderBottom"></div>
    </div>
    <span id="leftSpan1" style="
        color: #6d747e;
        position: fixed;
        left: 400px;
        top: 580px;
        font-size: 20px;
      ">单位：万元</span>
    <!-- 组件d86d3f90-3deb-11ec-a2e6-953464dcce14结束-->
    <!-- 左下echart图展示区域 -->
    <!-- 组件57e5c2f0-3e1a-11ec-9ac5-c10a0ae21a86开始-->
    <div id="leftBottom1" style="display: none"></div>
    <div id="leftBottom2" style="display: none"></div>
    <div id="leftBottom3" style="display: none"></div>
    <div id="leftBottom4"></div>
    <!-- 组件57e5c2f0-3e1a-11ec-9ac5-c10a0ae21a86结束-->
    <!-- 组件85e23400-9797-4236-9be8-186a0b1f24e2结束-->
  </div>
</template>

<script>
import { EventBus } from '../../../assets/js/eventBus.js'
import * as echarts from 'echarts'
export default {
  mounted() {
    this.leftBottom1Echarts()
    this.leftBottom2Echarts()
    this.leftBottom3Echarts()
    this.leftBottom4Echarts()
    EventBus.$on('leftToChild', msg => {
      // A发送来的消息
      console.log('event:' + msg)
      this.leftFlag = msg
      if (this.leftFlag === 2) {
        document.querySelector('.boderBottom').style.width = '300%'
        document.getElementById('leftSpan1').style.display = 'none'
        document.getElementById('leftBottomP').lastChild.textContent = '组织形式趋势'
        document.getElementById('leftBottom1').style.display = 'none'
        document.getElementById('leftBottom2').style.display = 'block'
        document.getElementById('leftBottom3').style.display = 'none'
        document.getElementById('leftBottom4').style.display = 'none'
      } else if (this.leftFlag === 3) {
        document.querySelector('.boderBottom').style.width = '380%'
        document.getElementById('leftSpan1').style.display = 'inline'
        document.getElementById('leftBottomP').lastChild.textContent = '项目金额排名'
        document.getElementById('leftBottom1').style.display = 'none'
        document.getElementById('leftBottom2').style.display = 'none'
        document.getElementById('leftBottom3').style.display = 'block'
        document.getElementById('leftBottom4').style.display = 'none'
      } else if (this.leftFlag === 4) {
        document.querySelector('.boderBottom').style.width = '380%'
        document.getElementById('leftSpan1').style.display = 'inline'
        document.getElementById('leftBottomP').lastChild.textContent = '成交金额排名'
        document.getElementById('leftBottom1').style.display = 'none'
        document.getElementById('leftBottom2').style.display = 'none'
        document.getElementById('leftBottom3').style.display = 'none'
        document.getElementById('leftBottom4').style.display = 'block'
      } else {
        document.querySelector('.boderBottom').style.width = '300%'
        document.getElementById('leftSpan1').style.display = 'none'
        document.getElementById('leftBottomP').lastChild.textContent = '采购方式成交趋势'
        document.getElementById('leftBottom1').style.display = 'block'
        document.getElementById('leftBottom2').style.display = 'none'
        document.getElementById('leftBottom3').style.display = 'none'
        document.getElementById('leftBottom4').style.display = 'none'
      }
    })
  },
  data() {
    return {
      leftFlag: '',
      echartsData1: [
        {
          purchaseWay: '单一来源',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 3274.01
            },
            {
              years: 2022,
              totalLastPrice: 43364.19
            },
            {
              years: 2023,
              totalLastPrice: 3619.33
            },
            {
              years: 2024,
              totalLastPrice: 555.66
            }
          ]
        },
        {
          purchaseWay: '电子卖场',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 0
            },
            {
              years: 2022,
              totalLastPrice: 0
            },
            {
              years: 2023,
              totalLastPrice: 0
            },
            {
              years: 2024,
              totalLastPrice: 71.44
            }
          ]
        },
        {
          purchaseWay: '定点采购',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 0
            },
            {
              years: 2022,
              totalLastPrice: 0
            },
            {
              years: 2023,
              totalLastPrice: 0
            },
            {
              years: 2024,
              totalLastPrice: 545.6
            }
          ]
        },
        {
          purchaseWay: '公开招标',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 47325.3
            },
            {
              years: 2022,
              totalLastPrice: 105536.07
            },
            {
              years: 2023,
              totalLastPrice: 121826.41
            },
            {
              years: 2024,
              totalLastPrice: 87321.58
            }
          ]
        },
        {
          purchaseWay: '竞争性磋商',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 688.51
            },
            {
              years: 2022,
              totalLastPrice: 25816.64
            },
            {
              years: 2023,
              totalLastPrice: 1806.8
            },
            {
              years: 2024,
              totalLastPrice: 19159.48
            }
          ]
        },
        {
          purchaseWay: '竞争性谈判',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 5847.17
            },
            {
              years: 2022,
              totalLastPrice: 8668.67
            },
            {
              years: 2023,
              totalLastPrice: 149.75
            },
            {
              years: 2024,
              totalLastPrice: 45
            }
          ]
        },
        {
          purchaseWay: '批量采购',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 0
            },
            {
              years: 2022,
              totalLastPrice: 0
            },
            {
              years: 2023,
              totalLastPrice: 218.3
            },
            {
              years: 2024,
              totalLastPrice: 17.92
            }
          ]
        },
        {
          purchaseWay: '网上竞价',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 0
            },
            {
              years: 2022,
              totalLastPrice: 0
            },
            {
              years: 2023,
              totalLastPrice: 20
            },
            {
              years: 2024,
              totalLastPrice: 0
            }
          ]
        },
        {
          purchaseWay: '协议供货',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 43.75
            },
            {
              years: 2022,
              totalLastPrice: 192.89
            },
            {
              years: 2023,
              totalLastPrice: 522.15
            },
            {
              years: 2024,
              totalLastPrice: 0
            }
          ]
        },
        {
          purchaseWay: '询价',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 0
            },
            {
              years: 2022,
              totalLastPrice: 0
            },
            {
              years: 2023,
              totalLastPrice: 299.61
            },
            {
              years: 2024,
              totalLastPrice: 188.4
            }
          ]
        },
        {
          purchaseWay: '邀请招标',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 0
            },
            {
              years: 2022,
              totalLastPrice: 798
            },
            {
              years: 2023,
              totalLastPrice: 0
            },
            {
              years: 2024,
              totalLastPrice: 3346
            }
          ]
        }
      ],
      echartsData2: [
        {
          wayType: '自行组织', // 组织形式名称
          yearsTotalLastPrice: [ //  组织形式对应各年份成交金额集合
            { //  （原理同上）
              years: 2021,
              totalLastPrice: 54167.72
            },
            {
              years: 2022,
              totalLastPrice: 159003.33
            },
            {
              years: 2023,
              totalLastPrice: 99545.18
            },
            {
              years: 2024,
              totalLastPrice: 88245.04
            }
          ]
        },
        {
          wayType: '委托国采',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 2871.26
            },
            {
              years: 2022,
              totalLastPrice: 3278.38
            },
            {
              years: 2023,
              totalLastPrice: 28548.29
            },
            {
              years: 2024,
              totalLastPrice: 22854.03
            }
          ]
        },
        {
          wayType: '委托代理机构',
          yearsTotalLastPrice: [
            {
              years: 2021,
              totalLastPrice: 0
            },
            {
              years: 2022,
              totalLastPrice: 22094.77
            },
            {
              years: 2023,
              totalLastPrice: 368.89
            },
            {
              years: 2024,
              totalLastPrice: 152
            }
          ]
        }
      ],
      echartsData3: [
        {
          recorderOrgName: '征管科技司', // 需求部门名称
          totalLastPrice: 200448.45 // 成交总金额
        },
        {
          recorderOrgName: '电子税务管理中心',
          totalLastPrice: 47635.12
        },
        {
          recorderOrgName: '数据风险办',
          totalLastPrice: 44602.21
        },
        {
          recorderOrgName: '数据风险局',
          totalLastPrice: 31633.9
        },
        {
          recorderOrgName: '国税系统',
          totalLastPrice: 27011.49
        },
        {
          recorderOrgName: '办公厅',
          totalLastPrice: 25411.07
        },
        {
          recorderOrgName: '电子税务中心',
          totalLastPrice: 22507.33
        },
        {
          recorderOrgName: '大企业管理司',
          totalLastPrice: 5705.31
        },
        {
          recorderOrgName: '服务中心',
          totalLastPrice: 5241.09
        },
        {
          recorderOrgName: '规划核算司',
          totalLastPrice: 4283.86
        }
      ],
      echartsData4: [
        {
          companyName: '中国软件与技术服务股份有限公司',
          totalLastPrice: 99697.99
        },
        {
          companyName: '税友软件集团股份有限公司',
          totalLastPrice: 76496.87
        },
        {
          companyName: '神州数码信息系统有限公司',
          totalLastPrice: 48357.94
        },
        {
          companyName: '中国印钞造币总公司',
          totalLastPrice: 41400
        },
        {
          companyName: '同方股份有限公司',
          totalLastPrice: 33991.39
        },
        {
          companyName: '联通系统集成有限公司',
          totalLastPrice: 25370
        },
        {
          companyName: '中国电信集团系统集成有限责任公司',
          totalLastPrice: 23953.33
        },
        {
          companyName: '联想（北京）有限公司',
          totalLastPrice: 21272.83
        },
        {
          companyName: '航天信息股份有限公司',
          totalLastPrice: 18618.22
        },
        {
          companyName: '云南南天电子信息产业股份有限公司',
          totalLastPrice: 9729.46
        }
      ]

    }
  },
  methods: {
    leftBottom4Echarts() {
      const myChart = echarts.init(document.getElementById('leftBottom4'))
      var getName = this.echartsData4.map(value => {
        return value.companyName
      })
      var getValue = this.echartsData4.map(value => {
        return value.totalLastPrice
      })
      getValue.sort((a, b) => b - a)
      var value = getValue.map(i => {
        return -i
      })
      var option = {
        grid: {
          left: '8%',
          right: '2%',
          bottom: '2%',
          top: '2%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          formatter: function (params) {
            return params[0].name + ' : ' + -params[0].value
          }
        },
        xAxis: {
          show: false
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            offset: 140,
            positon: 'left',
            interval: 10,
            triggerEvent: true,
            axisLabel: {
              show: true,
              align: 'left',
              textStyle: {
                color: '#66cc00'
              },
              formatter: function (item, index) {
                var str = ''
                var value = item
                var num = index + 1
                if (item.length >= 10) {
                  value = item.slice(0, 9) + '...'
                }
                if (index === 0) {
                  str = '{num1|' + num + '} {title1|' + value + '}'
                } else if (index === 1) {
                  str = '{num2|' + num + '} {title2|' + value + '}'
                } else if (index === 2) {
                  str = '{num3|' + num + '} {title3|' + value + '}'
                } else {
                  str = '{num|' + num + '} {title|' + value + '}'
                }

                return str
              },
              rich: {
                num: {
                  color: '#fff',
                  backgroundColor: '#8e8989',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num1: {
                  color: '#fff',
                  backgroundColor: '#ff4d4d',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num2: {
                  color: '#fff',
                  backgroundColor: '#4dd2ff',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num3: {
                  color: '#fff',
                  backgroundColor: '#ffff00',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                title: {
                  color: '#bfb7b7'
                },
                title1: {
                  color: '#ff4d4d'
                },
                title2: {
                  color: '#4dd2ff'
                },
                title3: {
                  color: '#ffff00'
                }
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                width: 2,
                color: '#586fa0'
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: getName
          },
          {
            type: 'category',
            inverse: true,
            offset: 0,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#81e4ff',
                fontSize: '20'
              }
            },
            data: getValue
          }
        ],
        series: [
          {
            name: '值',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: 'rgb(128,204,255,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(46,200,207,1)'
                  }
                ])
              }
            },
            barWidth: 15,
            data: value
          }
        ]
      }
      myChart.setOption(option)
    },
    leftBottom1Echarts() {
      const myChart = echarts.init(document.getElementById('leftBottom1'))
      var dataName = this.echartsData1.map((value) => { return value.purchaseWay })
      var dataValue = this.echartsData1.map((value) => { return value.yearsTotalLastPrice })
      var option = {
        grid: {
          left: '10%',
          right: '5%',
          top: '20%',
          bottom: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#bfb7b7'
            }
          }
        },
        legend: {
          show: true,
          x: 'center',
          y: '10',
          icon: 'stack',
          itemWidth: 18,
          itemHeight: 18,
          itemGap: 30,
          textStyle: {
            color: '#bfb7b7',
            fontSize: 18
          },
          nameTextStyle: {
            color: '#bfb7b7'
          },
          data: dataName
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              color: '#bfb7b7',
              fontSize: 16
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#0D1543'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#0D1543'
              }
            },
            data: ['2021', '2022', '2023', '2024']
          }
        ],
        yAxis: [
          {
            type: 'value',
            nameTextStyle: {
              color: '#fff',
              fontSize: 14
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: '#bfb7b7',
                fontSize: 16
              }
            },
            axisLine: {
              lineStyle: {
                color: '#0D1543'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.2)',
                width: 1
              }
            }
          }
        ],
        series: [
          {
            name: dataName[0],
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,

            itemStyle: {
              normal: {
                color: '#0092f6',
                lineStyle: {
                  color: '#0092f6',
                  width: 1
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: 'rgba(7,44,90,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,146,246,0.9)'
                    }
                  ])
                }
              }
            },
            data: dataValue[0].map((value) => { return value.totalLastPrice })
          },
          {
            name: dataName[1],
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,

            itemStyle: {
              normal: {
                color: '#00d4c7',
                lineStyle: {
                  color: '#00d4c7',
                  width: 1
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: 'rgba(7,44,90,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,212,199,0.9)'
                    }
                  ])
                }
              }
            },
            data: dataValue[1].map((value) => { return value.totalLastPrice })
          },
          {
            name: dataName[2],
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,

            itemStyle: {
              normal: {
                color: '#f2ac35',
                lineStyle: {
                  color: '#f2ac35',
                  width: 1
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: 'rgba(244, 157, 17,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(244, 157, 17,0.9)'
                    }
                  ])
                }
              }
            },
            data: dataValue[2].map((value) => { return value.totalLastPrice })
          },
          {
            name: dataName[3],
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,

            itemStyle: {
              normal: {
                color: '#f707f7',
                lineStyle: {
                  color: '#f707f7',
                  width: 1
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: 'rgba(185, 80, 201,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(185, 80, 201,0.9)'
                    }
                  ])
                }
              }
            },
            data: dataValue[3].map((value) => { return value.totalLastPrice })
          }
        ]
      }

      myChart.setOption(option)
    },
    leftBottom2Echarts() {
      const myChart = echarts.init(document.getElementById('leftBottom2'))
      var option
      var dataName = this.echartsData2.map((value) => { return value.wayType })
      var dataValue = this.echartsData2.map((value) => { return value.yearsTotalLastPrice })
      dataValue.forEach((value, index, arr) => { arr[index].totalLastPrice = value.totalLastPrice })
      option = {
        grid: {
          left: '10%',
          right: '5%',
          top: '20%',
          bottom: '10%',
          containLabel: true
        },
        legend: {
          x: '50',
          icon: 'stack',
          y: '0',
          itemWidth: 18,
          itemHeight: 18,
          itemGap: 30,
          data: dataName,
          textStyle: {
            color: '#bfb7b7',
            fontSize: 16
          }
        },
        xAxis: [
          {
            type: 'category',
            data: ['2021', '2022', '2023', '2024'],
            axisLabel: {
              color: '#bfb7b7',
              fontSize: 16
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: true,
              lineStyle: {
                color: '#233e64'
              }
            },
            axisLabel: {
              color: '#bfb7b7',
              fontSize: 16
            }
          }
        ],
        series: dataValue
      }
      myChart.setOption(option)
    },
    leftBottom3Echarts() {
      const myChart = echarts.init(document.getElementById('leftBottom3'))
      var getName = this.echartsData3.map(value => {
        return value.recorderOrgName
      })
      var getValue = this.echartsData3.map(value => {
        return value.totalLastPrice
      })
      var value = getValue.map(i => {
        return -i
      })
      var option = {
        grid: {
          left: '8%',
          right: '2%',
          bottom: '2%',
          top: '2%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'none'
          },
          formatter: function (params) {
            return params[0].name + ' : ' + -params[0].value
          }
        },
        xAxis: {
          show: false
        },
        yAxis: [
          {
            type: 'category',
            inverse: true,
            offset: 140,
            positon: 'left',
            interval: 10,
            triggerEvent: true,
            axisLabel: {
              show: true,
              align: 'left',
              textStyle: {
                color: '#66cc00'
              },
              formatter: function (item, index) {
                var str = ''
                var value = item
                var num = index + 1
                if (item.length >= 10) {
                  value = item.slice(0, 9) + '...'
                }
                if (index === 0) {
                  str = '{num1|' + num + '} {title1|' + value + '}'
                } else if (index === 1) {
                  str = '{num2|' + num + '} {title2|' + value + '}'
                } else if (index === 2) {
                  str = '{num3|' + num + '} {title3|' + value + '}'
                } else {
                  str = '{num|' + num + '} {title|' + value + '}'
                }

                return str
              },
              rich: {
                num: {
                  color: '#fff',
                  backgroundColor: '#8e8989',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num1: {
                  color: '#fff',
                  backgroundColor: '#ff4d4d',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num2: {
                  color: '#fff',
                  backgroundColor: '#4dd2ff',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                num3: {
                  color: '#fff',
                  backgroundColor: '#ffff00',
                  width: 20,
                  height: 20,
                  fontSize: 14,
                  align: 'center',
                  borderRadius: 100
                },
                title: {
                  color: '#bfb7b7'
                },
                title1: {
                  color: '#ff4d4d'
                },
                title2: {
                  color: '#4dd2ff'
                },
                title3: {
                  color: '#ffff00'
                }
              }
            },
            splitLine: {
              show: false,
              lineStyle: {
                width: 2,
                color: '#586fa0'
              }
            },
            axisTick: {
              show: false
            },
            axisLine: {
              show: false
            },
            data: getName
          },
          {
            type: 'category',
            inverse: true,
            offset: 0,
            axisTick: 'none',
            axisLine: 'none',
            show: true,
            axisLabel: {
              textStyle: {
                color: '#81e4ff',
                fontSize: '20'
              }
            },
            data: getValue
          }
        ],
        series: [
          {
            name: '值',
            type: 'bar',
            zlevel: 1,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
                  {
                    offset: 0,
                    color: 'rgb(128,204,255,1)'
                  },
                  {
                    offset: 1,
                    color: 'rgb(46,200,207,1)'
                  }
                ])
              }
            },
            barWidth: 15,
            data: value
          }
        ]
      }
      myChart.setOption(option)
    }
  }
}
</script>

<style scoped>
p {
  font-size: 16px;
  color: #fff;
}

img {
  vertical-align: -10px;
}

.boderBottom {
  width: 300%;
  height: 4px;
  background: rgba(69, 148, 173, 1);
  background: linear-gradient(to right, rgba(69, 148, 173, 1), rgba(69, 148, 173, 0));
  margin-top: 10px;
}

#leftBottom1,
#leftBottom2,
#leftBottom3,
#leftBottom4 {
  position: absolute;
  left: 50px;
  top: 630px;
  width: 450px;
  height: 320px;
  z-index: 9;
  display: block;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}

#itemd86d3f90-3deb-11ec-a2e6-953464dcce14 {
  position: absolute;
  margin-left: -150px;
  top: 580px;
}
</style>
