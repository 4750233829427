<template>
  <div>
    <!-- 左中标题头 -->
    <!-- 组件1550b630-3dec-11ec-a2e6-953464dcce14开始-->
    <div id="item1550b630-3dec-11ec-a2e6-953464dcce14" :style="{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '18px',
      lineHeight: '1.5',
      color: '#ffffff',
      textAlign: 'center',
      textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : '',
    }" class="centertitle">
      <p>按采购方式统计</p>
    </div>

    <!-- 组件1550b630-3dec-11ec-a2e6-953464dcce14结束-->
    <!-- 左中echarts图展示区域 -->
    <!-- 组件a1c42d00-3deb-11ec-a2e6-953464dcce14开始-->
    <div id="leftCentershowWindow2">
      <div class="innerBox">
        <div id="leftCenterEchartsBox21"></div>
        <div id="leftCenterEchartsBox22"></div>
        <div id="leftCenterEchartsBox23"></div>
      </div>
    </div>
    <!-- 组件a1c42d00-3deb-11ec-a2e6-953464dcce14结束-->
    <!-- 左中容器背景图 -->
    <!-- 组件0c892655-9dbb-497c-bec9-239cca87c34a开始-->
    <!-- 外框背景图_中间发光 -->
    <div id="item0c892655-9dbb-497c-bec9-239cca87c34a">
      <img src="../../../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
    </div>
    <!-- 组件0c892655-9dbb-497c-bec9-239cca87c34a结束-->
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  mounted() {
    this.setEcharts('leftCenterEchartsBox21', 0)
    this.setEcharts('leftCenterEchartsBox22', 1)
    this.setEcharts('leftCenterEchartsBox23', 0)
  },
  data() {
    return {
      // 模拟数据
      resData: [
        {
          purchaseWay: '竞争性磋商', // 采购方式名称
          totalPlanAmount: 63909.61, // 采购方式对应项目总预算
          capitalSavingRate: 0.2572, // 采购方式对应生产总值增长率
          projectNums: 56, // 采购方式对应项目数量
          totalLastPrice: 47471.43 // 采购方式对应项目总成交（中标）金额
        },
        {
          purchaseWay: '公开招标',
          totalPlanAmount: 402045.66,
          capitalSavingRate: 0.0996,
          projectNums: 220,
          totalLastPrice: 362009.37
        },
        {
          purchaseWay: '单一来源',
          totalPlanAmount: 72923.44,
          capitalSavingRate: 0.3032,
          projectNums: 46,
          totalLastPrice: 50813.2
        },
        {
          purchaseWay: '协议供货',
          totalPlanAmount: 731.43,
          capitalSavingRate: 0.0224,
          projectNums: 13,
          totalLastPrice: 715.04
        },
        {
          purchaseWay: '批量采购',
          totalPlanAmount: 253,
          capitalSavingRate: 0.0663,
          projectNums: 5,
          totalLastPrice: 236.22
        },
        {
          purchaseWay: '电子卖场',
          totalPlanAmount: 89.19,
          capitalSavingRate: 0.199,
          projectNums: 2,
          totalLastPrice: 71.44
        },
        {
          purchaseWay: '竞争性谈判',
          totalPlanAmount: 16707.31,
          capitalSavingRate: 0.1195,
          projectNums: 20,
          totalLastPrice: 14710.59
        },
        {
          purchaseWay: '定点采购',
          totalPlanAmount: 680.47,
          capitalSavingRate: 0.1982,
          projectNums: 5,
          totalLastPrice: 545.6
        },
        {
          purchaseWay: '邀请招标',
          totalPlanAmount: 5991.68,
          capitalSavingRate: 0.3084,
          projectNums: 3,
          totalLastPrice: 4144
        },
        {
          purchaseWay: '询价',
          totalPlanAmount: 679.72,
          capitalSavingRate: 0.282,
          projectNums: 2,
          totalLastPrice: 488.01
        },
        {
          purchaseWay: '网上竞价',
          totalPlanAmount: 49,
          capitalSavingRate: 0.5918,
          projectNums: 1,
          totalLastPrice: 20
        }
      ],
      bannerIdex: 0 // 初始化banner的下标
    }
  },
  methods: {
    /*
      创建echarts图
      @param id: 要画echarts的容器
      @param flag: 统计项目的内容
      - flag = 1 : 统计内容为项目数量柱状图
      - flag = 0 : 统计内容为招商金额柱状图、中标金额柱状图和生产总值增长率折线图
    */
    setEcharts(id, flag) {
      // 根据id如果是图2展示项目数量和中标招商金额，否则展示中标金额和生产总值增长率
      const showEchartsData = !flag
        ? [
          {
            name: '招商金额',
            type: 'bar',
            barMaxWidth: 30, // 柱最大宽度
            data: []
          },
          {
            name: '中标金额',
            type: 'bar',
            data: []
          },
          {
            name: '生产总值增长率',
            type: 'line',
            yAxisIndex: 1,
            data: []
          }
        ]
        : [
          {
            name: '项目数量',
            type: 'bar',
            barMaxWidth: 30,
            data: []
          }
        ]
      const xData = []
      this.resData.forEach((item) => {
        xData.push(item.purchaseWay)
        if (!flag) {
          showEchartsData[0].data.push(item.totalPlanAmount)
          showEchartsData[1].data.push(item.totalLastPrice)
          showEchartsData[2].data.push(Number(item.capitalSavingRate * 100).toFixed(2))
        } else {
          showEchartsData[0].data.push(item.projectNums)
        }
      })
      var chartDom = document.getElementById(id)
      var myChart = echarts.init(chartDom)
      var option
      option = {
        tooltip: {
          // trigger: 'axis',
          // axisPointer: {
          //   type: 'cross',
          //   crossStyle: {
          //     color: '#999'
          //   }
          // }
        },
        toolbox: {
          // feature: {
          //   dataView: { show: true, readOnly: false },
          //   magicType: { show: true, type: ['line', 'bar'] },
          //   restore: { show: true },
          //   saveAsImage: { show: true }
          // }
        },
        legend: {
          data: !flag ? ['招商金额', '中标金额', '生产总值增长率'] : ['项目数量'],
          textStyle: {
            color: '#fff'
          }
        },
        grid: {
          containLabel: false // 是否自适应x, y 轴文字的宽度
        },
        xAxis: [
          {
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow'
            },
            axisLabel: {
              interval: 0, // 全部展示
              rotate: '45' // x轴字体倾斜角度
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: !flag ? '招商金额' : '项目数量',
            axisLabel: {
              formatter: '{value}',
              rotate: !flag ? '45' : '0'
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#375C77'
              }
            }
          },
          {
            type: 'value',
            name: !flag ? '生产总值增长率' : '',
            axisLabel: {
              formatter: !flag ? '{value}%' : ''
            },
            splitLine: {
              show: false
            }
          }
        ],
        series: showEchartsData
      }

      option && myChart.setOption(option)
    },
    /*
      banner滚动
      @param n: 当前展示的第几张图，从0开始
      @param bufferMove: 函数(能够实现元素的减速运动)
    */
    changeBanner(n, bufferMove) {
      const bannerWrap = document.querySelector('.innerBox')
      if (n === 3) {
        bannerWrap.style.left = 0
        n = 1
      }
      bufferMove(
        bannerWrap,
        {
          left: -n * 450
        },
        30
      )
    }
  }
}
</script>

<style scoped>
/* 背景图的位置及大小 */
#item0c892655-9dbb-497c-bec9-239cca87c34a {
  top: 320px;
  left: 20px;
  width: 530px;
  height: 310px;
}

/* 标题位置 */
#item1550b630-3dec-11ec-a2e6-953464dcce14 {
  top: 334px;
}

#leftCentershowWindow2 {
  position: absolute;
  left: 70px;
  top: 370px;
  width: 450px;
  height: 270px;
  overflow: hidden;
}

#leftCentershowWindow2 .innerBox {
  display: flex;
  position: absolute;
  top: 2px;
  left: 0px;
  height: 270px;
  width: 1350px;
}

#leftCentershowWindow2 .innerBox div {
  position: absolute;
  top: 0px;
  width: 450px;
  height: 270px;
  z-index: 8;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}

#leftCenterEchartsBox21 {
  left: 0px;
}

#leftCenterEchartsBox22 {
  left: 450px;
}

#leftCenterEchartsBox23 {
  left: 900px;
}
</style>
