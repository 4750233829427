<template>
  <div id="content5">
    <table rules="none" class="showContent">
      <thead>
        <tr>
          <th>序号</th>
          <th>项目名称</th>
          <th>技术部门</th>
          <th>项目金额(万元)</th>
          <th class="tableHeadDays" v-for="item in showDays" :key="item" :style="{ width: showDaysWidth }">
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in resData" :key="item.projectId">
          <td>{{ item.no }}</td>
          <td>{{ item.projectName }}</td>
          <td>{{ item.companyName }}</td>
          <td>{{ item.companyPencent }}</td>
          <td v-for="i in showDays" :key="i" class="planPhaseItem">
            <div v-for="j in item.plan" :key="j.planPhase">
              <span
                v-if="Number(i) === Number(j.planTime)"
                :class="j.planStatus === '01' ? 'actived' : j.planStatus === '02' ? 'active' : ''"
              >
                {{ j.planPhase }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 模拟数据
      resData: [
        // 主体数据
        {
          no: 1,
          projectId: '00001',
          projectName: 'XXX采购项目1',
          companyName: '税务所',
          companyPencent: '142.30',
          plan: [
            { planPhase: '意向阶段', planTime: '04', planStatus: '01' },
            { planPhase: '需求阶段', planTime: '06', planStatus: '01' },
            { planPhase: '计划阶段', planTime: '10', planStatus: '02' },
            { planPhase: '采购文件', planTime: '13', planStatus: '03' },
            { planPhase: '采购公告', planTime: '16', planStatus: '03' },
            { planPhase: '开评标', planTime: '18', planStatus: '03' },
            { planPhase: '评审结果', planTime: '21', planStatus: '03' },
            { planPhase: '结果公告', planTime: '23', planStatus: '03' },
            { planPhase: '签订合同', planTime: '25', planStatus: '03' }
          ]
        },
        {
          no: 2,
          projectId: '00002',
          projectName: 'XXX采购项目2',
          companyName: '征科司',
          companyPencent: '132.40',
          plan: [
            { planPhase: '意向阶段', planTime: '02', planStatus: '01' },
            { planPhase: '需求阶段', planTime: '05', planStatus: '01' },
            { planPhase: '计划阶段', planTime: '08', planStatus: '02' },
            { planPhase: '采购文件', planTime: '11', planStatus: '03' },
            { planPhase: '采购公告', planTime: '14', planStatus: '03' },
            { planPhase: '开评标', planTime: '20', planStatus: '03' },
            { planPhase: '评审结果', planTime: '24', planStatus: '03' },
            { planPhase: '结果公告', planTime: '27', planStatus: '03' },
            { planPhase: '签订合同', planTime: '30', planStatus: '03' }
          ]
        },
        {
          no: 3,
          projectId: '00003',
          projectName: 'XXX采购项目3',
          companyName: '征科司',
          companyPencent: '231.40',
          plan: [
            { planPhase: '意向阶段', planTime: '03', planStatus: '01' },
            { planPhase: '需求阶段', planTime: '07', planStatus: '01' },
            { planPhase: '计划阶段', planTime: '12', planStatus: '02' },
            { planPhase: '采购文件', planTime: '15', planStatus: '03' },
            { planPhase: '采购公告', planTime: '17', planStatus: '03' },
            { planPhase: '开评标', planTime: '19', planStatus: '03' },
            { planPhase: '评审结果', planTime: '22', planStatus: '03' },
            { planPhase: '结果公告', planTime: '24', planStatus: '03' },
            { planPhase: '签订合同', planTime: '26', planStatus: '03' }
          ]
        },
        {
          no: 4,
          projectId: '00004',
          projectName: 'XXX采购项目4',
          companyName: '征科司',
          companyPencent: '431.20',
          plan: [
            { planPhase: '意向阶段', planTime: '04', planStatus: '01' },
            { planPhase: '需求阶段', planTime: '06', planStatus: '01' },
            { planPhase: '计划阶段', planTime: '10', planStatus: '01' },
            { planPhase: '采购文件', planTime: '13', planStatus: '01' },
            { planPhase: '采购公告', planTime: '16', planStatus: '01' },
            { planPhase: '开评标', planTime: '18', planStatus: '01' },
            { planPhase: '评审结果', planTime: '21', planStatus: '01' },
            { planPhase: '结果公告', planTime: '23', planStatus: '02' },
            { planPhase: '签订合同', planTime: '25', planStatus: '03' }
          ]
        }
      ],
      showDays: [], // 当前统计月的日
      showDaysWidth: '40px' // 根据当前统计月的天数计算剩余空间分配每个格多宽
    }
  },
  mounted () {
    this.getThisMonthDays()
  },
  methods: {
    getThisMonthDays () {
      const year = this.$parent.statisticalMonth.substr(0, 4)
      const month = this.$parent.statisticalMonth.substr(-2)
      const Timedate = new Date(year, month, 0)
      const days = Timedate.getDate()
      this.showDaysWidth = parseInt(1244 / days, 10) + 'px'
      this.showDays = []
      for (let i = 1; i < days + 1; i++) {
        this.showDays.push(i)
      }
      console.log(this.showDays)
    }
  }
}
</script>

<style scoped>
#content5 {
  position: absolute;
  top: 90px;
  left: 35px;
  width: 1820px;
  height: 910px;
  padding: 20px;
  background: url(../../../assets/images/作战图内容背景.png) no-repeat;
  font-size: 16px;
}
.showContent {
  color: #ccc;
}
.showContent thead tr {
  height: 50px;
  background-color: #0a1a51;
}
.showContent thead tr th:nth-child(1) {
  width: 70px;
}
.showContent thead tr th:nth-child(2) {
  width: 260px;
}
.showContent thead tr th:nth-child(3) {
  width: 120px;
}
.showContent thead tr th:nth-child(4) {
  width: 120px;
}
.showContent tbody tr td {
  height: 60px;
  text-align: center;
  line-height: 60px;
}
.showContent tbody tr td:nth-child(2) {
  text-align: left;
}
.planPhaseItem div {
  position: relative;
  width: 100%;
  height: 0;
}
.planPhaseItem div span {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  width: 40px;
  height: 40px;
  text-align: center;
  background-color: #2f568b;
  line-height: 20px;
}
.planPhaseItem div .actived {
  background-color: #1f7a53;
  color: #fff;
}
.planPhaseItem div .active {
  background-color: #1dd386;
  box-shadow: 0 0 30px 6px #1dd386;
  color: #fff;
}
</style>
