<template>
  <!-- 外框、设置背景图 -->
  <div class="container">
    <!-- 头部通栏 -->
    <div id="item88b51490-3e05-11ec-a2e6-953464dcce14">
      <!-- 头部标题 -->
      <!-- 组件头部标题开始-->
      <div id="item4ec4a890-3dec-11ec-a2e6-953464dcce14" class="centertitle">
        <h1>2022年国税总局采购数据</h1>
      </div>
      <!-- 组件头部标题结束-->

      <!-- 头部通栏背景图 -->
      <!-- 组件头部背景图开始-->
      <div id="item139cfda0-3dea-11ec-a2e6-953464dcce14">
        <img src="../../assets/images/顶部栏.png" alt="" :style="{ width: '100%', height: '200%' }" />
      </div>
      <!-- 组件头部背景图结束-->

      <!-- 上部分左边 -->
      <top-left />

      <!-- 上部分中间 -->
      <top-center />

      <!-- 右边开始 -->
      <top-right />

      <!-- 下方内容 -->
      <bottom-content />
    </div>
  </div>
</template>

<script>
import topLeft from './component/TopLeft'
import topCenter from './component/TopCenter'
import topRight from './component/TopRight'
import bottomContent from './component/BottomContent'

export default {
  components: {
    topLeft,
    topCenter,
    topRight,
    bottomContent
  },
  mounted() {
    // 组件实现出场动画
    this.$nextTick(() => {
      //  头部
      playAnimation('#item88b51490-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInDown',
          name: '向下移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      //  左
      playAnimation('#item8389fda0-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInLeft',
          name: '向右移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      // 中间
      playAnimation('#itemc2579760-3e0d-11ec-9df4-83999b721ef6', [
        {
          type: 'bounceInDown',
          name: '向下弹入',
          delayed: 0.5,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])
      // 右
      playAnimation('#item7a111060-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInRight',
          name: '向左移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      /**
       *
       * @param {*} id
       * @param {*} animations
       * 声明初始值
       * 判断动画是否结束
       */
      function playAnimation(id, animations) {
        let index = 0
        const ele = document.querySelector(id)
        setAnimation(ele, animations[index])
        ele.addEventListener('webkitAnimationEnd', function () {
          if (index < animations.length - 1) {
            index++
            setAnimation(ele, animations[index])
          }
        })
      }

      /**
       *
       * @param {*} element
       * @param {*} animation
       * 取index对应的动画
       * 给元素animation重新赋值
       */
      function setAnimation(element, animation) {
        const action = `${animation.duration * 1000}ms ease ${animation.delayed * 1000}ms ${animation.loop ? 'infinite' : animation.frequency
          } normal both running ${animation.type}`
        element.style.animation = action
      }
    })
    // 自适应宽度处理
    document.getElementsByClassName('container')[0].style.zoom = document.body.clientWidth / 1920
    // 窗口发生改变自动刷新页面
    function fandou(time) {
      let timeout = null
      return function () {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          location.reload()
        }, time)
      }
    }
    window.onresize = fandou(1000)
  }
}
</script>

<style scoped>
#item4ec4a890-3dec-11ec-a2e6-953464dcce14 {
  top: 6px;
}

.container::after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background: url(../../assets/images/内容背景.png) no-repeat 0 80px;
  background-size: 100% 95%;
}

#item4ec4a890-3dec-11ec-a2e6-953464dcce14 h1 {
  letter-spacing: 10px;
  font-weight: bold;
  color: #fff;
  font-size: 36px;
}
</style>
