<template>
  <div class="container">
    <!-- right top-->
    <div id="item9f27ace0-4933-11ec-b3da-3947daa0c333" style="margin-top: -40px"></div>
    <!-- right bottom-->
    <div id="item0de69de0-4933-11ec-b3da-3947daa0c333"></div>
    <!-- middle bottom 其他 -->
    <div id="item4e0b7d8a-4f60-486f-8bbb-f09c042a3709" style="margin-left: 10px">
      <!-- 组件aebd97fd-6275-435f-af17-0909ef6eded1开始-->
      <div class="bottom_right">
        <div style="color: #00e0fe; font-weight: bold">其他</div>
        <div style="margin-top: 7px">
          <span>总数量：</span><span style="position: absolute; right: 32px">{{ form.purchaseOthorNum }}个</span>
        </div>
        <div style="margin-top: 7px">
          <span>占百分比：</span><span style="position: absolute; right: 32px">{{ form.purchaseOthorProportion }}%</span>
        </div>
        <div style="margin-top: 7px">
          <span>总价值：</span><span style="position: absolute; right: 32px">{{ form.purchaseOthorAmount }}万元</span>
        </div>
      </div>
      <div id="itemaebd97fd-6275-435f-af17-0909ef6eded1" style="right: 20px; height: 310px">
        <img src="../assets/images/线框.png" alt="" :style="{ width: '90%', height: '45%', marginTop: '98px' }" />
      </div>
      <!-- 组件aebd97fd-6275-435f-af17-0909ef6eded1结束-->
    </div>
    <!-- 采购四处-->
    <div id="item16600876-79d2-4b1f-940b-e0fe92fbaa05" style="margin-left: 15px">
      <!-- 组件7cb0124d-1530-4005-9fce-d366ffd3e62c开始-->
      <div class="bottom_right">
        <div style="color: #00e0fe; font-weight: bold">森消物资</div>
        <div style="margin-top: 7px">
          <span>总数量：</span><span style="position: absolute; right: 32px">{{ form.purchase4Num }}个</span>
        </div>
        <div style="margin-top: 7px">
          <span>占百分比：</span><span style="position: absolute; right: 32px">{{ form.purchase4Proportion }}%</span>
        </div>
        <div style="margin-top: 7px">
          <span>总价值：</span><span style="position: absolute; right: 32px">{{ form.purchase4Amount }}万元</span>
        </div>
      </div>
      <div id="item7cb0124d-1530-4005-9fce-d366ffd3e62c" style="right: 20px; height: 310px">
        <img src="../assets/images/线框.png" alt="" :style="{ width: '90%', height: '45%', marginTop: '98px' }" />
      </div>
      <!-- 组件7cb0124d-1530-4005-9fce-d366ffd3e62c结束-->
    </div>
    <!-- left bottom -->
    <div id="itemaf79cbb0-4932-11ec-b3da-3947daa0c333" style="margin-top: 5px"></div>
    <!-- left img -->
    <div id="item8389fda0-3e05-11ec-a2e6-953464dcce14">
      <!-- 组件1550b630-3dec-11ec-a2e6-953464dcce14开始-->

      <div id="item1550b630-3dec-11ec-a2e6-953464dcce14" :style="{
        width: '300px',
        marginLeft: '-85px',
        marginTop: '-5px',
        color: '#00e0fe',
        fontSize: '18px',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
      }">
        <p>近3天物资库存情况</p>
      </div>
      <!-- 组件1550b630-3dec-11ec-a2e6-953464dcce14结束-->

      <!-- 组件d86d3f90-3deb-11ec-a2e6-953464dcce14开始-->

      <div id="itemd86d3f90-3deb-11ec-a2e6-953464dcce14" :style="{
        width: '300px',
        marginLeft: '-70px',
        marginTop: '5px',
        color: '#00e0fe',
        fontSize: '18px',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
      }">
        <p>恒温物资仓储温度变化</p>
      </div>
      <!-- 组件d86d3f90-3deb-11ec-a2e6-953464dcce14结束-->

      <!-- 组件05cd9ca0-3dec-11ec-a2e6-953464dcce14开始-->

      <div id="item05cd9ca0-3dec-11ec-a2e6-953464dcce14" :style="{
        width: '220px',
        marginLeft: '-50px',
        top: '-2px',
        color: '#00e0fe',
        fontSize: '18px',
        textAlign: 'left',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
      }">
        <p>近10秒上报信息分析情况</p>
      </div>
      <!-- 组件05cd9ca0-3dec-11ec-a2e6-953464dcce14结束-->

      <!-- 组件a1c42d00-3deb-11ec-a2e6-953464dcce14开始-->

      <div id="itema1c42d00-3deb-11ec-a2e6-953464dcce14"></div>
      <!-- 组件a1c42d00-3deb-11ec-a2e6-953464dcce14结束-->

      <!-- 组件8d374050-3e10-11ec-b331-d9ec61910d4a开始-->

      <div id="item8d374050-3e10-11ec-b331-d9ec61910d4a" style="top: 20px"></div>
      <!-- 组件8d374050-3e10-11ec-b331-d9ec61910d4a结束-->

      <!-- 组件85e23400-9797-4236-9be8-186a0b1f24e2开始-->

      <div id="item85e23400-9797-4236-9be8-186a0b1f24e2"
        style="right: 20px; width: 530px; height: 310px; top: 640px; left: 20px">
        <img src="../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
      </div>
      <!-- 组件85e23400-9797-4236-9be8-186a0b1f24e2结束-->

      <!-- 组件0c892655-9dbb-497c-bec9-239cca87c34a开始-->

      <div id="item0c892655-9dbb-497c-bec9-239cca87c34a"
        style="right: 20px; width: 530px; height: 310px; top: 315px; left: 20px">
        <img src="../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
      </div>
      <!-- 组件0c892655-9dbb-497c-bec9-239cca87c34a结束-->

      <!-- 组件da42a880-eaf0-4a65-b71f-a313e2bd4daf开始-->

      <div id="itemda42a880-eaf0-4a65-b71f-a313e2bd4daf" style="width: 530px; height: 310px; top: -15px; left: 20px">
        <img src="../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
      </div>
      <!-- 组件da42a880-eaf0-4a65-b71f-a313e2bd4daf结束-->
    </div>
    <!-- middle top -->
    <div id="item4d575c20-3e1c-11ec-9ac5-c10a0ae21a86" style="top: 160px">
      <!-- 组件104422f0-3e1c-11ec-9ac5-c10a0ae21a86开始-->

      <div id="item104422f0-3e1c-11ec-9ac5-c10a0ae21a86" :style="{
        width: '321.74px',
        left: '0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '24px',
        color: 'rgba(255, 255, 255, 1)',
        textAlign: 'center',
        textShadow: '0px 0px 10px,0px 0px 20px rgba(15, 146, 255,1)'
      }">
        <p>实时上报风险点位：{{ form.totalContracts }}个</p>
      </div>
      <!-- 组件104422f0-3e1c-11ec-9ac5-c10a0ae21a86结束-->

      <!-- 分组c81405f0-3e1a-11ec-9ac5-c10a0ae21a86开始-->
      <div id="itemc81405f0-3e1a-11ec-9ac5-c10a0ae21a86">
        <!-- 组件3cea5f20-3e19-11ec-b331-d9ec61910d4a开始-->

        <div id="item3cea5f20-3e19-11ec-b331-d9ec61910d4a" :style="{
          top: '10px',
          width: '400px',
          display: 'flex',
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '50px',
          color: '#217ff2',
          textShadow: '0px 0px 10px,0px 0px 40px rgba(15, 146, 255,1)'
        }">
          <div class="dis-row">
            <span style="font-size: 30px; margin-right: 6px">排除</span>
            {{ form.contractsAmount }}
            <span style="font-size: 30px; margin-left: 6px">个点位</span>
          </div>
        </div>
        <!-- 组件3cea5f20-3e19-11ec-b331-d9ec61910d4a结束-->
      </div>
      <!-- 分组c81405f0-3e1a-11ec-9ac5-c10a0ae21a86结束-->
    </div>
    <!-- 采购一处-->
    <div id="itemabc0b440-3e0e-11ec-b331-d9ec61910d4a" style="margin-left: 20px">
      <!-- 组件f8180e4e-6d50-49d4-8027-d9726079a076开始-->
      <div class="bottom_right">
        <div style="color: #00e0fe; font-weight: bold">防汛物资</div>
        <div style="margin-top: 7px">
          <span>总数量：</span><span style="position: absolute; right: 40px">{{ form.purchase1Num }}个</span>
        </div>
        <div style="margin-top: 7px">
          <span>占百分比：</span><span style="position: absolute; right: 40px">{{ form.purchase1Proportion }}%</span>
        </div>
        <div style="margin-top: 7px">
          <span>总价值：</span><span style="position: absolute; right: 40px">{{ form.purchase1Amount }}万元</span>
        </div>
      </div>
      <div id="itemf8180e4e-6d50-49d4-8027-d9726079a076" style="right: 20px; height: 310px">
        <img src="../assets/images/线框.png" alt="" :style="{ width: '90%', height: '45%', marginTop: '98px' }" />
      </div>
      <!-- 组件f8180e4e-6d50-49d4-8027-d9726079a076结束-->
    </div>
    <!-- middle img-->
    <div id="itemc2579760-3e0d-11ec-9df4-83999b721ef6">
      <!-- 组件ea2e0360-3e07-11ec-a2e6-953464dcce14开始-->
      <img src="../assets/images/中间.png" alt="" :style="{ width: '100%', height: '100%' }" />
      <!-- 组件ea2e0360-3e07-11ec-a2e6-953464dcce14结束-->
    </div>
    <!-- top img-->
    <div id="item88b51490-3e05-11ec-a2e6-953464dcce14">
      <!-- 组件4ec4a890-3dec-11ec-a2e6-953464dcce14开始-->

      <div id="item4ec4a890-3dec-11ec-a2e6-953464dcce14">
        <div style="width: 500px;">应急指挥调度系统综合统计</div>
      </div>
      <!-- 组件4ec4a890-3dec-11ec-a2e6-953464dcce14结束-->

      <!-- 组件139cfda0-3dea-11ec-a2e6-953464dcce14开始-->

      <div id="item139cfda0-3dea-11ec-a2e6-953464dcce14">
        <img src="../assets/images/top.png" alt="" :style="{ width: '100%', height: '50%', marginTop: '30px' }" />
      </div>
      <!-- 组件139cfda0-3dea-11ec-a2e6-953464dcce14结束-->
    </div>
    <!-- right img-->
    <div id="item7a111060-3e05-11ec-a2e6-953464dcce14">
      <!-- 组件9c61daa0-3e05-11ec-a2e6-953464dcce14开始-->

      <div id="item9c61daa0-3e05-11ec-a2e6-953464dcce14" :style="{
        width: '300px',
        marginLeft: '-45px',
        marginTop: '-25px',
        color: '#00e0fe',
        fontSize: '18px',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
      }">
        <p>物资数量统计</p>
      </div>
      <!-- 组件9c61daa0-3e05-11ec-a2e6-953464dcce14结束-->

      <!-- 组件d00a0940-3e05-11ec-a2e6-953464dcce14开始-->

      <div id="itemd00a0940-3e05-11ec-a2e6-953464dcce14" :style="{
        width: '300px',
        marginLeft: '-35px',
        marginTop: '-10px',
        color: '#00e0fe',
        fontSize: '18px',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
      }">
        <p>关键点位人员实时观测</p>
      </div>
      <!-- 组件d00a0940-3e05-11ec-a2e6-953464dcce14结束-->

      <!-- 组件40b1de80-3e05-11ec-a2e6-953464dcce14开始-->

      <div id="item40b1de80-3e05-11ec-a2e6-953464dcce14"></div>
      <!-- 组件40b1de80-3e05-11ec-a2e6-953464dcce14结束-->

      <!-- 组件d0cac130-3e05-11ec-a2e6-953464dcce14开始-->

      <div id="itemd0cac130-3e05-11ec-a2e6-953464dcce14" :style="{
        width: '300px',
        marginLeft: '-42px',
        marginTop: '9px',
        color: '#00e0fe',
        fontSize: '18px',
        textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
      }">
        <p>节点冗余情况</p>
      </div>
      <!-- 组件d0cac130-3e05-11ec-a2e6-953464dcce14结束-->

      <!-- 组件71b4b580-3deb-11ec-a2e6-953464dcce14开始-->

      <div id="item71b4b580-3deb-11ec-a2e6-953464dcce14"
        style="right: 20px; width: 530px; height: 310px; top: 640px; left: 20px">
        <img src="../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
      </div>
      <!-- 组件71b4b580-3deb-11ec-a2e6-953464dcce14结束-->

      <!-- 组件677df680-3deb-11ec-a2e6-953464dcce14开始-->

      <div id="item677df680-3deb-11ec-a2e6-953464dcce14"
        style="right: 20px; width: 530px; height: 310px; top: 315px; left: 20px">
        <img src="../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
      </div>
      <!-- 组件677df680-3deb-11ec-a2e6-953464dcce14结束-->

      <!-- 组件63811bd0-3dea-11ec-a2e6-953464dcce14开始-->

      <div id="item63811bd0-3dea-11ec-a2e6-953464dcce14"
        style="right: 20px; width: 530px; height: 310px; top: -15px; left: 20px">
        <img src="../assets/images/线框.png" alt="" :style="{ width: '100%', height: '100%' }" />
      </div>
      <!-- 组件63811bd0-3dea-11ec-a2e6-953464dcce14结束-->
    </div>
    <div class="topEnd" style="margin-top: 200px; margin-left: -250px; display: inline">
      <span style="color: #e5dcdc; font-size: 16px">总数量:</span>
      <span style="color: #00e0fe; margin-left: 10px; font-size: 26px">
        {{ rightTopData[0].value + rightTopData[1].value + rightTopData[2].value }}件</span>
    </div>
    <div class="topEnd">
      <div style="margin-top: 20px; margin-left: 20px">
        <p style="font-size: 14px">防汛物资</p>
        <p style="font-size: 24px; font-weight: bold; color: #00ffff">
          {{ rightTopData[2].value }}
        </p>
      </div>
      <div style="margin-top: 20px; margin-left: 20px">
        <p style="font-size: 14px">森消物资</p>
        <p style="font-size: 24px; font-weight: bold; color: #f49e13">
          {{ rightTopData[1].value }}
        </p>
      </div>
      <div style="margin-top: 20px; margin-left: 20px">
        <p style="font-size: 14px">其他物资</p>
        <p style="font-size: 24px; font-weight: bold; color: #f27b72">
          {{ rightTopData[0].value }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/css/index.css'
import '../assets/css/reset.css'
import '../assets/css/custom-animation.css'
import * as echarts from 'echarts'

let interval = undefined
export default {
  data() {
    return {
      form: {
        totalContracts: 51563,
        contractsAmount: 651531.6,
        purchase1Num: 861,
        purchase1Proportion: 846,
        purchase1Amount: 8526,
        purchase4Num: 8624,
        purchase4Proportion: 8652,
        purchase4Amount: 8326,
        purchaseOthorNum: 5486,
        purchaseOthorProportion: 8661,
        purchaseOthorAmount: 186
      },
      rightTopData: [
        {
          value: 5486,
          name: '其他物资'
        },
        {
          value: 8624,
          name: '森消物资'
        },
        {
          value: 861,
          name: '防汛物资'
        }
      ],
      rightCenterData: [101, 56, 80],
      rightBottomData: [
        [32, 13, 20, 14, 20, 23, 21],
        [22, 12, 11, 23, 29, 33, 31]
      ],
      leftTopData: [
        {
          value: 120,
          name: '已识别'
        },
        {
          value: 180,
          name: '待识别'
        }
      ],
      leftCenterData: [
        [250, 100, 150],
        [400, 200, 300]
      ],
      leftBottomData: [
        [373, 128, 69],
        [124784.0, 118364.4, 14960.2]
      ],
      leftMyChart: undefined,
      leftBottomCharts: undefined,
      rightCenterCharts: undefined
    }
  },
  mounted() {
    this.$nextTick(() => {
      playAnimation('#item4e0b7d8a-4f60-486f-8bbb-f09c042a3709', [
        {
          type: 'fadeInUp',
          name: '向上移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      playAnimation('#item16600876-79d2-4b1f-940b-e0fe92fbaa05', [
        {
          type: 'fadeInUp',
          name: '向上移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      playAnimation('#item8389fda0-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInLeft',
          name: '向右移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      playAnimation('#item4d575c20-3e1c-11ec-9ac5-c10a0ae21a86', [
        {
          type: 'fadeInDown',
          name: '向下移入',
          delayed: 0.5,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      playAnimation('#itemabc0b440-3e0e-11ec-b331-d9ec61910d4a', [
        {
          type: 'fadeInUp',
          name: '向上移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      playAnimation('#itemc2579760-3e0d-11ec-9df4-83999b721ef6', [
        {
          type: 'bounceInDown',
          name: '向下弹入',
          delayed: 0.5,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      playAnimation('#item88b51490-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInDown',
          name: '向下移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      playAnimation('#item7a111060-3e05-11ec-a2e6-953464dcce14', [
        {
          type: 'fadeInRight',
          name: '向左移入',
          delayed: 0,
          loop: false,
          frequency: 1,
          duration: 1,
          isPlayer: false,
          isDisabled: false
        }
      ])

      /**
       *
       * @param {*} id
       * @param {*} animations
       * 声明初始值
       * 判断动画是否结束
       */

      function playAnimation(id, animations) {
        let index = 0
        const ele = document.querySelector(id)
        setAnimation(ele, animations[index])
        ele.addEventListener('webkitAnimationEnd', function () {
          if (index < animations.length - 1) {
            index++
            setAnimation(ele, animations[index])
          }
        })
      }

      /**
       *
       * @param {*} element
       * @param {*} animation
       * 取index对应的动画
       * 给元素animation重新赋值
       */

      function setAnimation(element, animation) {
        const action = `${animation.duration * 1000}ms ease ${animation.delayed * 1000}ms ${animation.loop ? 'infinite' : animation.frequency
          } normal both running ${animation.type}`
        element.style.animation = action
      }
    })

    // 自适应宽度处理

    document.getElementsByClassName('container')[0].style.zoom = window.innerWidth / 1920
    // 窗口发生改变自动刷新页面
    function fandou(time) {
      let timeout = null
      return function () {
        clearTimeout(timeout)
        timeout = setTimeout(() => {
          location.reload()
        }, time)
      }
    }
    window.onresize = fandou(1000)
    // 右上角
    this.rightTop()
    // 右中
    this.rightCenter()
    // 右下角
    this.rightBottom()
    // 左上角
    this.leftTop()
    // 左中
    this.leftCenter()
    // 左下角
    this.leftBottom()

    interval = setInterval(() => {
      this._intervalDom()
    }, 1000)
  },
  beforeDestroy() {
    interval && clearInterval(interval)
  },
  methods: {
    _getRandomInt(min, max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min
    },
    _intervalDom() {
      this.form.totalContracts = this._getRandomInt(300, 500)
      this.form.contractsAmount = this._getRandomInt(100, 500)
      const ysb = this._getRandomInt(20, this.form.totalContracts)
      this.leftMyChart.setOption({
        title: {
          subtext: this.form.totalContracts + '个'
        },
        series: [{
          data: [
            {
              value: ysb,
              name: '已识别'
            },
            {
              value: this.form.totalContracts - ysb,
              name: '待识别'
            }
          ]
        }]
      })
      const dataLeftBottom = [this._getRandomInt(20, 28),
      this._getRandomInt(20, 28),
      this._getRandomInt(20, 28)
      ]
      this.leftBottomCharts.setOption({
        series: [{
          data: dataLeftBottom
        }, {
          data: [
            dataLeftBottom[0] * 3000,
            dataLeftBottom[1] * 3000,
            dataLeftBottom[2] * 3000
          ]
        }]
      })
      this.rightCenterCharts.setOption({
        series: [{
          data: [
            this._getRandomInt(300, 330),
            this._getRandomInt(200, 210),
            this._getRandomInt(350, 360)
          ]
        }]
      })
    },
    rightTop() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('item9f27ace0-4933-11ec-b3da-3947daa0c333'))
      var option = {
        tooltip: {
          trigger: 'item',
          formatter: '{b} : {d}% <br/> {c}'
        },
        color: ['#00FFFF', '#f49e13', '#f27b72', '#e84a22'],
        series: [
          {
            type: 'pie',
            itemStyle: {
              borderWidth: 3
            },
            radius: ['30%', '50%'],
            center: ['50%', '50%'],
            data: [
              {
                value: this.rightTopData[0].value,
                label: {
                  fontSize: '16px',
                  color: '#00FFFF'
                },
                name: this.rightTopData[0].name
              },
              {
                value: this.rightTopData[1].value,
                label: {
                  fontSize: '16px',
                  color: '#f49e13'
                },
                name: this.rightTopData[1].name
              },
              {
                value: this.rightTopData[2].value,
                label: {
                  fontSize: '16px',
                  color: '#f27b72'
                },
                name: this.rightTopData[2].name
              }
            ],
            label: {
              normal: {
                formatter: '{b|{b}}\n{d|{d}%}',
                padding: [0, -10],
                rich: {
                  b: {
                    fontSize: '14px',
                    color: '#fff'
                  },
                  d: {
                    fontSize: '16px',
                    align: 'center'
                  }
                }
              }
            }
          }
        ]
      }
      myChart.setOption(option)
    },
    rightBottom() {
      // 基于准备好的dom，初始化echarts实例
      const myChart = echarts.init(document.getElementById('item0de69de0-4933-11ec-b3da-3947daa0c333'))
      var option = {
        grid: {
          left: '10%',
          right: '10%',
          top: '25%',
          bottom: '5%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            lineStyle: {
              color: '#bfb7b7'
            }
          }
        },
        legend: {
          show: true,
          x: 'center',
          y: '10',
          icon: 'stack',
          itemWidth: 14,
          itemHeight: 14,
          textStyle: {
            color: '#bfb7b7',
            fontSize: 18
          },
          nameTextStyle: {
            color: '#bfb7b7'
          },
          data: ['状态1', '状态2', '状态3', '状态4']
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLabel: {
              color: '#bfb7b7',
              fontSize: 16
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: '#0D1543'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: '#0D1543'
              }
            },
            data: ['设备1', '设备2', '设备3', '设备4', '设备5', '设备6', '设备7']
          }
        ],
        yAxis: [
          {
            name: '节点数量/个',
            type: 'value',
            nameTextStyle: {
              color: '#fff',
              fontSize: 14
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: '#bfb7b7',
                fontSize: 16
              }
            },
            axisLine: {
              lineStyle: {
                color: '#0D1543'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.2)',
                width: 1
              }
            }
          },
          {
            name: '冗余数量/个',
            type: 'value',
            nameTextStyle: {
              color: '#fff',
              fontSize: 14
            },
            axisLabel: {
              formatter: '{value}',
              textStyle: {
                color: '#bfb7b7',
                fontSize: 16
              }
            },
            axisLine: {
              lineStyle: {
                color: '#0D1543'
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: 'rgba(255,255,255,0.2)',
                width: 1
              }
            }
          }
        ],
        series: [
          {
            name: '状态1',
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,

            itemStyle: {
              normal: {
                color: '#0092f6',
                lineStyle: {
                  color: '#0092f6',
                  width: 1
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: 'rgba(7,44,90,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,146,246,0.9)'
                    }
                  ])
                }
              }
            },
            data: this.rightBottomData[0]
          },
          {
            yAxisIndex: 1,
            name: '状态2',
            type: 'line',
            symbol: 'circle',
            symbolSize: 8,

            itemStyle: {
              normal: {
                color: '#00d4c7',
                lineStyle: {
                  color: '#00d4c7',
                  width: 1
                },
                areaStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: 'rgba(7,44,90,0.3)'
                    },
                    {
                      offset: 1,
                      color: 'rgba(0,212,199,0.9)'
                    }
                  ])
                }
              }
            },
            data: this.rightBottomData[1]
          }
        ]
      }
      myChart.setOption(option)
    },
    leftBottom() {
      // 基于准备好的dom，初始化echarts实例
      this.leftBottomCharts = echarts.init(document.getElementById('itemaf79cbb0-4932-11ec-b3da-3947daa0c333'))
      var data1 = this.leftBottomData[0]
      var data2 = this.leftBottomData[1]
      var xData = ['近30s', '近20s', '近10s']
      var maxY = 0
      var maxMoney = 0
      for (var i = 0; i < data1.length; i++) {
        if (data1[i] > maxY) {
          maxY = data1[i]
        }
        if (data2[i] > maxMoney) {
          maxMoney = data2[i]
        }
      }
      // maxY = (parseInt(maxY / 10) + 1) * 10
      maxY = 30
      const option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            textStyle: {
              color: '#05294D'
            }
          },
          borderColor: '#135469',
          borderWidth: 2,
          padding: [20, 20, 20, 20],
          formatter: function (params) {
            // console.log(params.length == 0);
            if (params.length === 1) {
              const paramsArray = []
              params.forEach(v => {
                // console.log(v.name);
                if (v.seriesName === '趋势') {
                  v.color = '#F3C452'
                } else {
                  v.color = '#02DBF1'
                }
                paramsArray.push({
                  name: v.name,
                  seriesName: v.seriesName,
                  data: v.data,
                  color: v.color
                })
              })
              // console.log(paramsArray);
              let result =
                '<span style="font-size: 24px;font-family: MicrosoftYaHeiUISemibold;color: #FFFFFF;ine-height: 41px;">' +
                paramsArray[0].name +
                '</span>' +
                '<br>'
              result +=
                '<div style="display:flex;">' +
                '<div style="font-size: 20px;font-family: MicrosoftYaHeiUI;color: ' +
                paramsArray[0].color +
                ';line-height: 25px;margin-top:10px;margin-right:28px;">' +
                '' +
                paramsArray[0].seriesName +
                '<div style="font-size: 24px;font-family: DINAlternate-Bold, DINAlternate;font-weight: bold;color:' +
                paramsArray[0].color +
                ';line-height: 33px;">' +
                paramsArray[0].data +
                '万元' +
                '</div>' +
                '</div>' +
                '</div>'
              return result
            } else {
              let result =
                '<span style="font-size: 24px;font-family: MicrosoftYaHeiUISemibold;color: #FFFFFF;ine-height: 41px;">' +
                params[0].name +
                '</span>' +
                '<br>'
              result +=
                '<div style="display:flex;">' +
                '<div style="font-size: 20px;font-family: MicrosoftYaHeiUI;color: #02DBF1;line-height: 25px;margin-top:10px;margin-right:28px;">' +
                '' +
                params[0].seriesName +
                '<div style="font-size: 24px;font-family: DINAlternate-Bold, DINAlternate;font-weight: bold;color: #02DBF1;line-height: 33px;">' +
                params[0].data +
                '个' +
                '</div>' +
                '</div>' +
                '<div style="margin-top:10px;font-size: 20px;font-family: MicrosoftYaHeiUI;color: #F3C452;line-height: 25px;">' +
                '' +
                params[1].seriesName +
                '<div style="font-size: 24px;font-family: DINAlternate-Bold, DINAlternate;font-weight: bold;color: #F3C452;line-height: 33px;">' +
                params[1].data +
                '万元' +
                '</div>' +
                '</div>' +
                '</div>'
              return result
            }
          }
        },
        grid: {
          borderWidth: 0,
          left: '65',
          bottom: '11%',
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          top: '2%',
          left: '25%',
          textStyle: {
            fontSize: '18px',
            fontFamily: 'MicrosoftYaHeiUI',
            color: '#FFFFFF'
          },
          itemWidth: 20,
          itemHeight: 20,
          data: [
            {
              name: '实时温度',
              icon:
                'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAA1ElEQVR4Xu2WsQ2AMBADP4ghYBtGZxsYAhHEAMQ19tF+g+/PSVqFfy08fwEAA8IJUIFwATgEqQAVCCdABcIF4BagAlQgnAAVCBeAW4AKUIFwAlQgXABuASpABcIJjCtw9O3vfOaquta2f+UYApjO3v8O4P3/e2mfOQEw2jAGpFeg0g9BhwNQZeAlqAi5zzHAfcMqHwYoQu5zDHDfsMqHAYqQ+xwD3Des8mGAIuQ+xwD3Dat8GKAIuc8xwH3DKh8GKELucwxw37DKhwGKkPscA9w3rPI9drIYQbytq9EAAAAASUVORK5CYII='
            },
            {
              name: '变化比例',
              icon:
                'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAA1UlEQVRYR+2UMQ7CMAxFvxdyFtqVhREpZ+AEnIyRM1RiY2NsOQgTXYzSUlVNm9ZWhwrJnvPjpxfHhI2LNu4PAzADZsAM/I+BT5FnAF8JqBl0cb6sNFu0z/N751+nLisy0ISJb2DkTZBQgukshYjyT+ergxhgEA6NQwUQIcQoH4HPGpgKtwZ+NhYglpq3MhM1F5ZcLDmTBJCE1wImZ0DSvAuvfaKRAU3zJIRySAczUBfZg4GjdMInIZTfNALY3wFy6xaNblGJFpFm42nPGoAZMANmwAx8ASjjyiGj/fCxAAAAAElFTkSuQmCC'
            }
          ]
        },
        xAxis: [
          {
            type: 'category',
            axisLine: {
              lineStyle: {
                color: 'rgba(255,255,255,.5)'
              }
            },
            splitLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitArea: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: 'rgba(255,255,255,0.7)',
              fontSize: 18
            },
            data: xData
          }
        ],
        yAxis: [
          {
            type: 'value',
            min: 0,
            max: maxY,
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              interval: 0,
              color: 'rgba(255,255,255,0.5)',
              fontSize: 18
            },
            splitArea: {
              show: false
            }
          },
          {
            type: 'value',
            min: 0,
            max: maxMoney + 10,
            splitLine: {
              show: false
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false
            },
            splitArea: {
              show: false
            }
          }
        ],

        series: [
          {
            name: '实时温度',
            type: 'bar',
            // "stack": "总量",
            barMaxWidth: 35,
            barGap: '10%',
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#05F5FF' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#020D20' // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              }
            },
            data: data1
          },
          {
            name: '变化比例',
            type: 'line',
            yAxisIndex: 1,
            symbolSize: 10,
            symbol: 'circle',
            itemStyle: {
              normal: {
                color: 'rgba(255, 196, 53, 1)',
                barBorderRadius: 0
              }
            },
            lineStyle: {
              normal: {
                width: 4,
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: '#F0B62A' // 0% 处的颜色
                    },
                    {
                      offset: 0.45,
                      color: '#FFC12B' // 45% 处的颜色
                    },
                    {
                      offset: 0.85,
                      color: '#FFC12B' // 85% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#FFC12B' // 100% 处的颜色
                    }
                  ],
                  global: false // 缺省为 false
                }
              }
            },
            data: data2
          }
        ]
      }

      this.leftBottomCharts.setOption(option)
    },

    leftCenter() {
      // 基于准备好的dom，初始化echarts实例
      this.leftMyChart1 = echarts.init(document.getElementById('itema1c42d00-3deb-11ec-a2e6-953464dcce14'))
      var xData2 = ['前天', '昨天', '今天']
      var data1 = this.leftCenterData[0]
      var data2 = this.leftCenterData[1]
      var barWidth = 50
      const option = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params, ticket, callback) {
            var htmlStr = ''
            var param = params[0]
            var paramYzf = params[1]
            var xName = param.name
            htmlStr += xName + '<br/>'

            // 绘制总金额
            var dian =
              '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:red;"></span>'
            htmlStr += dian + '风险点总数' + '：' + form.totalContracts + '个'
            htmlStr += '<br/>'

            // 绘制已支付金额
            var dian1 =
              '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:#FFD56E;"></span>'
            htmlStr += dian1 + '已支付' + '：' + paramYzf.value + '亿'
            htmlStr += '<br/>'

            // 绘制未支付金额
            var wzfMoney = param.value - paramYzf.value
            var dian2 =
              '<span style="margin-right:5px;display:inline-block;width:10px;height:10px;border-radius:5px;background-color:#163F7A;"></span>'
            htmlStr += dian2 + '未支付' + '：' + wzfMoney + '亿'

            return htmlStr
          }
        },
        grid: {
          left: 40,
          bottom: 40
        },
        xAxis: {
          data: xData2,
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            interval: 0,
            textStyle: {
              color: '#bfb7b7',
              fontSize: 16
            },
            margin: 26
          }
        },
        yAxis: {
          splitLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            show: false,
            textStyle: {
              color: '#bfb7b7',
              fontSize: 16
            }
          }
        },
        series: [
          {
            // 上半截柱子
            name: '2023',
            type: 'bar',
            barWidth: barWidth,
            barGap: '-100%',
            z: 0,
            itemStyle: {
              color: '#163F7A',
              opacity: 0.7
            },
            data: data2
          },
          {
            name: '2024',
            type: 'bar',
            barWidth: barWidth,
            barGap: '-100%',
            itemStyle: {
              opacity: 0.7,
              color: function (params) {
                return new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#FF9A22' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#FFD56E' // 100% 处的颜色
                    }
                  ],
                  false
                )
              }
            },
            data: data1
          },
          {
            // 替代柱状图 默认不显示颜色，是最下方柱图（邮件营销）的value值 - 20
            type: 'bar',
            barWidth: barWidth,
            barGap: '-100%',
            stack: '广告',
            itemStyle: {
              color: 'transparent'
            },
            data: data1
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [barWidth, 15],
            symbolOffset: [0, -5],
            z: 12,
            symbolPosition: 'end',
            itemStyle: {
              color: '#163F7A',
              opacity: 1
            },
            label: {
              show: true,
              position: 'top',
              fontSize: 16,
              color: '#bfb7b7'
            },
            data: data2
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [barWidth, 15],
            symbolOffset: [0, -10],
            z: 12,
            itemStyle: {
              opacity: 1,
              color: function (params) {
                return new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#FF9A22' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#FFD56E' // 100% 处的颜色
                    }
                  ],
                  false
                )
              }
            },
            label: {
              show: true,
              position: 'top',
              fontSize: 16,
              color: '#bfb7b7'
              // formatter:(item)=>{
              //     console.log(item)
              //     return 'ssss'
              // }
            },
            symbolPosition: 'end',
            data: data1
          },
          {
            name: '',
            type: 'pictorialBar',
            symbolSize: [barWidth, 15],
            symbolOffset: [0, 5],
            z: 12,
            itemStyle: {
              opacity: 1,
              color: function (params) {
                return new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: '#FF9A22' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: '#FFD56E' // 100% 处的颜色
                    }
                  ],
                  false
                )
              }
            },
            data: [1, 1, 1]
          },
          {
            name: '第一圈',
            type: 'pictorialBar',
            symbolSize: [barWidth, 16],
            symbolOffset: [0, 11],
            z: 11,
            itemStyle: {
              normal: {
                color: '00e0fe',
                borderColor: '#3ACDC5',
                borderWidth: 9
              }
            },
            data: [1, 1, 1]
          },
          {
            name: '第二圈',
            type: 'pictorialBar',
            symbolSize: [barWidth + 20, 22],
            symbolOffset: [0, 17],
            z: 10,
            itemStyle: {
              normal: {
                color: '00e0fe',
                borderColor: '#02c3f1',
                borderWidth: 9
              }
            },
            data: [1, 1, 1]
          }
        ]
      }
      this.leftMyChart1.setOption(option)
    },

    leftTop() {
      // 基于准备好的dom，初始化echarts实例
      this.leftMyChart = echarts.init(document.getElementById('item8d374050-3e10-11ec-b331-d9ec61910d4a'))
      var total = this.form.totalContracts
      const option = {
        title: {
          text: '风险点总数',
          textAlign: 'center',
          x: '40%',
          y: '48%',
          textStyle: {
            color: '#bfb7b7',
            fontSize: 16,
            fontWeight: 'normal'
          },
          subtext: parseInt(total) + '个',
          subtextStyle: {
            fontSize: 24,
            color: '#00e0fe'
          }
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          icon: 'stack',
          top: 'center',
          right: '1%',
          itemGap: 50,
          itemWidth: 14,
          orient: 'vertical',
          textStyle: {
            color: '#bfb7b7',
            fontSize: 16
          }
        },
        series: [
          {
            color: ['#00e0fe', '#074cfc'],
            name: '付款情况',
            type: 'pie',
            radius: ['50%', '70%'],
            center: ['40%', '60%'],
            roseType: 'area',
            labelLine: {
              show: true,
              lineStyle: {
                width: 2
              }
            },
            label: {
              show: true,
              normal: {
                formatter: '{d|{d}%}\n{c|{c}}',
                rich: {
                  d: {
                    fontSize: '20px',
                    color: '#11f4f4'
                  },
                  c: {
                    fontSize: '16px',
                    color: '#fff'
                  }
                }
              }
            },
            itemStyle: {
              borderRadius: 10,
              borderColor: '#bfb7b7',
              borderWidth: 1
            },
            data: this.leftTopData
          }
        ]
      }
      this.leftMyChart.setOption(option)
    },
    rightCenter() {
      // 基于准备好的dom，初始化echarts实例
      this.rightCenterCharts = echarts.init(document.getElementById('item40b1de80-3e05-11ec-a2e6-953464dcce14'))
      const option = {
        grid: {
          top: '10%',
          left: '5%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: ['LED工业园', '新能源产业园', '通讯工业园'],
          axisLabel: {
            interval: 0,
            //	margin:15,
            textStyle: {
              color: '#65D5FF',
              fontStyle: 'normal',
              fontSize: 16
            }
          },
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(77, 128, 254, 0.2)'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: 'rgba(77, 128, 254, 0.2)'
            }
          }
        },
        yAxis: [
          {
            axisLabel: {
              show: false,
              textStyle: {
                color: '#65D5FF',
                fontStyle: 'normal',
                fontSize: 16
              }
            },
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: 'rgba(77, 128, 254, 0.2)'
              }
            }
          }
        ],

        series: [
          {
            name: 'LED工业园',
            type: 'pictorialBar',
            barWidth: '100%',
            label: {
              normal: {
                position: 'top',
                distance: 5,
                fontSize: 16,
                color: '#fff',
                /*
                                        borderColor: '#555',
                                                  borderWidth: 2,
                                                            borderRadius: 5,
                                                                      textBorderColor: '#000',
            textBorderWidth: 3,
                     */
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(0, 151, 251, 1)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(0, 34, 66, 0.2)' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                }
              }
            },
            symbol:
              'path://M12.000,-0.000 C12.000,-0.000 16.074,60.121 22.731,60.121 C26.173,60.121 -3.234,60.121 0.511,60.121 C7.072,60.121 12.000,-0.000 12.000,-0.000 Z',
            data: this.rightCenterData
          },
          {
            barGap: '100%',
            name: '新能源产业园',
            type: 'pictorialBar',
            barWidth: '100%',
            label: {
              normal: {
                position: 'top',
                distance: 5,
                fontSize: 16,
                borderWidth: 2,
                borderRadius: 5,
                textBorderColor: '#000',
                textBorderWidth: 3,
                color: '#fff',
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(25, 252, 119,1)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(74, 214, 132, 0.2)' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                }
              }
            },
            symbol:
              'path://M12.000,-0.000 C12.000,-0.000 16.074,60.121 22.731,60.121 C26.173,60.121 -3.234,60.121 0.511,60.121 C7.072,60.121 12.000,-0.000 12.000,-0.000 Z',
            data: this.rightCenterData
          },
          {
            name: '通讯工业园',
            type: 'pictorialBar',
            barWidth: '100%',
            label: {
              normal: {
                position: 'top',
                distance: 5,
                fontSize: 16,
                borderWidth: 2,
                borderRadius: 5,
                textBorderColor: '#000',
                textBorderWidth: 3,
                color: '#fff',
                show: true
              }
            },
            itemStyle: {
              normal: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(244, 169, 7, 1)' // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(211, 166, 67, 0.2)' // 100% 处的颜色
                    }
                  ],
                  globalCoord: false // 缺省为 false
                }
              }
            },
            symbol:
              'path://M12.000,-0.000 C12.000,-0.000 16.074,60.121 22.731,60.121 C26.173,60.121 -3.234,60.121 0.511,60.121 C7.072,60.121 12.000,-0.000 12.000,-0.000 Z',
            data: this.rightCenterData
          }
        ]
      }

      this.rightCenterCharts.setOption(option)
    }
  }
}
</script>

<style scoped>
.topEnd {
  position: absolute;
  left: 1732px;
  top: 125px;
  width: 250.61px;
  height: 200px;
  z-index: 12;
  color: azure;
  display: flex;
  flex-direction: column;
  opacity: 1;
  transform: rotate(0deg);
  background: rgba(255, 255, 255, 0);
  border: 0px solid #2ed64a;
  border-radius: 0px;
  padding: 0px;
}

#item4ec4a890-3dec-11ec-a2e6-953464dcce14 {
  width: 400px;
  position: absolute;
  top: -2px;
  margin-left: -27px;
  font-size: 35px;
  color: #00e0fe;
}

.container {
  background: url('../assets/images/bg.jpg') no-repeat;
}

.bottom_right {
  position: absolute;
  left: 10px;
  top: 100px;
  width: 95%;
  height: 202.55px;
  z-index: 3;
  font-size: 16px;
}

.bottom_right span {
  color: aliceblue;
}

@font-face {
  font-family: 'led';
  src: url('../assets/fonts/led.ttf');
}

.cube {
  width: 100%;
  height: 100%;
  font-family: 'led';
  margin-right: 10px;
  border-radius: 2px;
  background: #1e569921;
}

.cube .number {
  width: 100%;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  transform: translateY(0px);
}

.cube .number span.value {
  font-size: 40px;
  color: #3d9cff;
}

.cube.isMark {
  width: 20px;
  flex: none;
  margin: 0;
  margin-left: -10px;
  border: 0;
  background: transparent;
}

.cube.isMark .number {
  align-items: flex-end;
}

.prefix {
  flex: none;
  display: flex;
  align-items: center;
  font-size: 40px;
  margin-right: 10px;
  color: #3d9cff;
}

.sufffix {
  flex: none;
  display: flex;
  align-items: flex-end;
  font-size: 18px;
}

.dis-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
</style>
