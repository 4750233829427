var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"centertitle",style:({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '18px',
      lineHeight: '1.5',
      color: '#ffffff',
      textAlign: 'center',
      textShadow: false ? '0px 0px 14px rgba(15, 146, 255,1)' : ''
    }),attrs:{"id":"item05cd9ca0-3dec-11ec-a2e6-953464dcce14"}},[_c('h2',[_vm._v("业务数据分析")])]),_c('div',{attrs:{"id":"item8d374050-3e10-11ec-b331-d9ec61910d4a"}},[_c('span',{on:{"click":_vm.flag1}},[_vm._v("采购方式")]),_c('span',{on:{"click":_vm.flag2}},[_vm._v("组织形式")]),_c('span',{on:{"click":_vm.flag3}},[_vm._v("需求部门")]),_c('span',{on:{"click":_vm.flag4}},[_vm._v("供应商")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }